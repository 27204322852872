/* eslint-disable max-len */
import { useState } from 'react';

import {
  Button, PageHeader, Tag, Typography,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { getOrdersList } from 'configs';
import { observer } from 'mobx-react';
import TableBox from 'components/TableBox/TableBox';
import store from 'store/mainStore';

import OrderModal from './OrderModal';

const Orders = () => {
  const { dictionaries, setUpdateSubscribe } = store;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [order, setOrder] = useState<number>(0);

  const toogleModal = (id: string) => {
    setOrder(Number(id));
    if (isOpen) setUpdateSubscribe('Список заказов');
    setIsOpen(!isOpen);
  };

  const getColor = (state: string) => {
    const int = Number(state);
    switch (int) {
      case 13:
        return 'error';
      default:
        return 'processing';
    }
  };

  const COLUMN: any = [
    {
      title: 'Статус',
      dataIndex: 'state_name',
      width: 170,
      minWidth: 170,
      ellipsis: true,
      fixed: 'left',
      render: (row: any, rows: any) => (
        <Tag color={getColor(rows.state)}>
          {row}
        </Tag>
      ),
    },
    {
      title: 'Номер',
      fixed: 'left',
      dataIndex: 'id',
      width: 100,
      render: (id: any) => (<Typography.Link onClick={() => toogleModal(id)}>{`DKO-${id}`}</Typography.Link>),
      ellipsis: true,
    },
    {
      fixed: 'left',
      title: 'Имя заказа',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: 'Курс юань',
      dataIndex: 'exchange_rate',
      width: 100,
      minWidth: 100,
      ellipsis: true,
    },
    {
      title: 'Курс USD',
      dataIndex: 'exchange_rate_usd',
      width: 100,
      minWidth: 100,
      ellipsis: true,
    },
    {
      title: 'Вес товара',
      dataIndex: 'weigth',
      ellipsis: true,
    },
    {
      title: 'Переведено в заказ',
      dataIndex: 'cash_in_order',
      ellipsis: true,
    },
    {
      title: 'Стоимость товара',
      dataIndex: 'rubPrice',
      ellipsis: true,
    },
    {
      title: 'Доставка',
      dataIndex: 'deliveryCoast',
      ellipsis: true,
    },
    {
      title: 'Страховка',
      dataIndex: 'actual_ins',
      ellipsis: true,
    },
  ];

  const typeOptions = (dictionaries?.order_status || []).map((el: any) => ({
    label: el.name,
    value: el.id,
    search: el.name,
  })) || [];

  const filters = [
    {
      name: 'name',
      type: 'input',
      label: 'Название',
      placeholder: 'Выедите название заказа',
    },
    {
      name: 'state',
      type: 'select',
      label: 'Статус',
      placeholder: 'Выберите статус',
      options: typeOptions,
    },
    {
      name: 'id',
      type: 'input',
      label: 'Номер',
      placeholder: 'Выедите Номер заказа',
    },
  ];

  return (
    <>
      <PageHeader title="Заказы" />
      {isOpen ? <OrderModal isOpen={isOpen} toogle={toogleModal} id={order} /> : null}
      <TableBox
        title="Список заказов"
        extra={<Button type="primary" onClick={() => setIsOpen(true)} icon={<PlusCircleOutlined />}>Добавить заказ</Button>}
        column={COLUMN}
        filters={filters}
        config={getOrdersList}
      />
    </>
  );
};
export default observer(Orders);
