// import { DollarCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { getBalanceFlow, getSellsFlow } from 'configs';
import useRequest from 'hooks/useRequest';
import {
  DatePicker,
  PageHeader,
  Typography,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import './style.scss';
import { Box } from 'components/Box';
import moment from 'moment';
import TableBox from 'components/TableBox/TableBox';
import store from 'store/mainStore';
import { COLUMN_SELL_FLOW } from 'pages/Sells/SellFlow';
import BorderedBox from 'components/BorderedBox/BorderedBox';
import DataList from 'components/BorderedBox/DataList';

const { RangePicker } = DatePicker;

const fin_bonus = [15];
const salary_payment = [5, 14];
const fot_dop = [14];

const delivery = [14, 15, 185, 201, 111];
const gsm = [104];
const po = [170];
const bank = [181];
const gov = [182];
const promo = [105, 106, 107];
const dop = [53];
const transfer = [9];
const return_invest = [4];

const getMoneySpliter = (list: any[]) => {
  const result: any = {
    up_price: 0,
    positive_list: [],
    wait_list: [],
    total_profit: 0,
    total_profit_before: 0,
    total_amount: 0,
    total_amount_our: 0,
    total_amount_fab_cred: 0,
    marginlist: [],
    mPrices: [],
    mProfit: [],
    marginalMiddle: 0,
    marg: 0,
    credit: 0,
    total_null: [],
    total_null_amount: 0,
    tax: 0,
    payment_tax: 0,
    low_list: [],
    low_deal: 0,

  };
  if (!list) return result;

  result.positive_list = (list)
    .filter((el: any) => Number(el.unit_price) > 0);

  result.low_list = (list)
    .filter((el: any) => Number(el.unit_price) === 0);

  result.low_deal = result.low_list.reduce(
    (accumulator: any, currentValue: any) => accumulator + Number(currentValue.profit), 0,
  );

  result.total_profit = list.reduce(
    (accumulator: any, currentValue: any) => accumulator + Number(currentValue.profit), 0,
  );

  result.wait_list = list.filter((el: any) => Number(el.credit) > 0);

  result.credit = result.wait_list.reduce(
    (accumulator: any, currentValue: any) => accumulator + Number(currentValue.credit), 0,
  );

  result.tax = list.reduce(
    (accumulator: any, currentValue: any) => accumulator + Number(currentValue.tax), 0,
  );

  result.payment_tax = list.reduce(
    (accumulator: any, currentValue: any) => accumulator + Number(currentValue.payment_tax), 0,
  );

  result.up_price = result.positive_list.map((el: any) => el.up_price);

  result.total_amount = result.positive_list.reduce(
    (accumulator: any, currentValue: any) => accumulator + Number(currentValue.pay_sum), 0,
  );

  result.total_amount_our = result.positive_list.reduce(
    (accumulator: any, currentValue: any) => accumulator + Number(currentValue.profit), 0,
  );

  result.total_amount_fab_cred = result.positive_list.reduce(
    (accumulator: any, currentValue: any) => accumulator + Number(currentValue.fabric_coast), 0,
  );

  // MARGIN

  result.marginlist = result.positive_list.map((el: any) => el.marginal);

  result.mPrices = result.positive_list.map((el: any) => el.pay_sum / el.count_sell);

  result.mProfit = result.positive_list.map((el: any) => el.profit / el.count_sell);

  result.marginalMiddle = result.marginlist.reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue, 0,
  );

  result.marg = Number(((result.marginalMiddle / result.marginlist.length) * 100).toFixed(2));

  result.total_null = (list)
    .filter((el: any) => Number(el.unit_price) === 0);

  result.total_null_amount = result.total_null.reduce(
    (accumulator: any, currentValue: any) => accumulator + Number(currentValue.profit), 0,
  );
  return result;
};

const flowSplitter = (list: any[]) => {
  const result = {
    fin_bonus: 0,
    transfers: 0,
    salary_payment: 0,
    delivery: 0,
    fot_dop: 0,
    delivery_cny: 0,
    gsm: 0,
    po: 0,
    bank: 0,
    gov: 0,
    promo: 0,
    dop: 0,
    transfer: 0,
    return_invest: 0,
  };

  if (!list) return result;

  result.fin_bonus = list.reduce(
    (accumulator: any, currentValue: any) => (accumulator + (fin_bonus.includes(Number(currentValue.type)) ? Number(currentValue.amount) * Number(currentValue.rate) : 0)), 0,
  );

  result.salary_payment = list.reduce(
    (accumulator: any, currentValue: any) => (accumulator + (salary_payment.includes(Number(currentValue.type)) ? Number(currentValue.amount) * Number(currentValue.rate) : 0)), 0,
  );

  result.fot_dop = list.reduce(
    (accumulator: any, currentValue: any) => (accumulator + (fot_dop.includes(Number(currentValue.type)) ? Number(currentValue.amount) * Number(currentValue.rate) : 0)), 0,
  );

  result.delivery = list.reduce(
    (accumulator: any, currentValue: any) => (accumulator + (delivery.includes(Number(currentValue.to_id)) ? Number(currentValue.amount) * Number(currentValue.rate) : 0)), 0,
  );

  result.gsm = list.reduce(
    (accumulator: any, currentValue: any) => (accumulator + (gsm.includes(Number(currentValue.to_id)) ? Number(currentValue.amount) * Number(currentValue.rate) : 0)), 0,
  );

  result.po = list.reduce(
    (accumulator: any, currentValue: any) => (accumulator + (po.includes(Number(currentValue.to_id)) ? Number(currentValue.amount) * Number(currentValue.rate) : 0)), 0,
  );
  result.bank = list.reduce(
    (accumulator: any, currentValue: any) => (accumulator + (bank.includes(Number(currentValue.to_id)) ? Number(currentValue.amount) * Number(currentValue.rate) : 0)), 0,
  );
  result.gov = list.reduce(
    (accumulator: any, currentValue: any) => (accumulator + (gov.includes(Number(currentValue.to_id)) ? Number(currentValue.amount) * Number(currentValue.rate) : 0)), 0,
  );
  result.promo = list.reduce(
    (accumulator: any, currentValue: any) => (accumulator + (promo.includes(Number(currentValue.to_id)) ? Number(currentValue.amount) * Number(currentValue.rate) : 0)), 0,
  );
  result.dop = list.reduce(
    (accumulator: any, currentValue: any) => (
      accumulator + (dop.includes(Number(currentValue.to_id)) && !fin_bonus.includes(Number(currentValue.type)) ? Number(currentValue.amount) * Number(currentValue.rate) : 0)
    ), 0,
  );
  result.transfer = list.reduce(
    (accumulator: any, currentValue: any) => (
      accumulator + (transfer.includes(Number(currentValue.type)) && !dop.includes(Number(currentValue.to_id)) ? Number(currentValue.amount) * Number(currentValue.rate) : 0)
    ), 0,
  );

  result.return_invest = list.reduce(
    (accumulator: any, currentValue: any) => (accumulator + (return_invest.includes(Number(currentValue.type)) ? Number(currentValue.amount) * Number(currentValue.rate) : 0)), 0,
  );

  return result;
};
const Dash = () => {
  const { dictionaries } = store;

  const typeOptions = (dictionaries?.sale_type || []).map((el: any) => ({
    label: el.name,
    value: el.id,
    search: el.name,
  })) || [];
  const { fetch, requestResult: cash_flow } = useRequest(getBalanceFlow);
  const { fetch: fetchLastSell, requestResult: lastSell } = useRequest(getSellsFlow);
  const [dates, setDates] = useState<string>(`${moment().startOf('month').format('YYYY-MM-DD')}|${moment().endOf('month').format('YYYY-MM-DD')}`);

  useEffect(() => {
    fetchLastSell({}, { perPage: 10000, page: 1, date_period: dates });
    fetch({}, { perPage: 10000, page: 1, date_period: dates });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  const filters = [
    {
      name: 'state',
      type: 'select',
      label: 'Статус',
      placeholder: 'Выберите статус',
      options: typeOptions,
    },
    {
      name: 'pay_sum',
      type: 'input',
      label: 'Сумма',
      placeholder: 'Введите сумму',
    },
    {
      name: 'sell_id',
      type: 'input',
      label: 'ID заказа',
      placeholder: 'Введите ID',
    },
    {
      name: 'name',
      type: 'input',
      label: 'Наименование товара',
      placeholder: 'Введите наименование',
    },
  ];

  const data = getMoneySpliter(lastSell?.records);

  const flow_data = flowSplitter(cash_flow?.records);

  const total_dop = flow_data.fin_bonus + flow_data.salary_payment + flow_data.gsm + flow_data.po + flow_data.promo + flow_data.dop + flow_data.return_invest;
  const total_gov = flow_data.gov + flow_data.bank;

  const moneyItems = [
    {
      title: 'Получено с продаж',
      value: data.total_amount.toLocaleString(),
    },
    {
      title: 'Ожидают оплаты',
      value: data.credit.toLocaleString(),
    },
    {
      title: 'Получено на сегодня',
      value: data.total_profit.toLocaleString(),
    },
    {
      title: 'Подарочки',
      value: <Typography.Text type="danger">{data.low_deal.toLocaleString()}</Typography.Text>,
    },
    {
      title: 'Полчим после оплат',
      value: (data.total_profit + data.credit).toLocaleString(),
    },
    {
      title: 'Прибыль по товарке',
      value: (data.total_profit - total_gov - total_dop).toLocaleString(),
    },
    {
      title: 'Прибыль после вычетов',
      value: (data.total_profit - total_gov - total_dop - flow_data.transfer - flow_data.return_invest).toLocaleString(),
    },
  ];

  const TaxItems = [
    {
      title: 'Начислено налогов',
      value: data.tax.toLocaleString(),
    },
    {
      title: 'Олачено налогов',
      value: flow_data.gov.toLocaleString(),
    },
    {
      title: 'Комисии платежных систем',
      value: data.payment_tax.toLocaleString(),
    },
    {
      title: 'Услуги банка',
      value: flow_data.bank.toLocaleString(),
    },
    {
      title: 'Переведено за товары',
      value: flow_data.transfer.toLocaleString(),
    },
  ];

  const dopItems = [
    {
      title: 'Волонтерсво',
      value: flow_data.fin_bonus.toLocaleString(),
    },
    {
      title: 'ФОТ (весь)',
      value: flow_data.salary_payment.toLocaleString(),
    },
    {
      title: 'ФОТ (премии и бонусы)',
      value: flow_data.fot_dop.toLocaleString(),
    },
    {
      title: 'Возврат диведенды',
      value: flow_data.return_invest.toLocaleString(),
    },
    {
      title: 'ГСМ',
      value: flow_data.gsm.toLocaleString(),
    },
    {
      title: 'Оплаты ПО',
      value: flow_data.po.toLocaleString(),
    },
    {
      title: 'Реклама',
      value: flow_data.promo.toLocaleString(),
    },
    {
      title: 'Доп расходы',
      value: flow_data.dop.toLocaleString(),
    },
    {
      title: 'Итого',
      value: total_dop.toLocaleString(),
    },
  ];

  return (
    <>
      <PageHeader
        title="Мониторинг продаж"
        extra={(
          <RangePicker
            style={{ width: '300px', height: '32px', background: 'transparent', marginLeft: '20px', borderRadius: '10px' }}
            defaultValue={[moment().startOf('month'), moment()]}
            onChange={(v: any) => setDates(`${moment(v[0]).format('YYYY-MM-DD')}|${moment(v[1]).format('YYYY-MM-DD')}`)}
          />
)}
      />
      <Content>
        <Box>
          <Box direction="row" justify="space-between" margin={[0, 0, 5, 0]}>
            <BorderedBox style={{ width: 'calc(100% / 3 - 30px)' }}>
              <>
                <Typography.Text type="secondary">Продажи</Typography.Text>
                <DataList list={moneyItems} />
              </>
            </BorderedBox>
            <BorderedBox style={{ width: 'calc(100% / 3 - 30px)' }}>
              <>
                <Typography.Text type="secondary">Налоги и комисси</Typography.Text>
                <DataList list={TaxItems} />
              </>
            </BorderedBox>
            <BorderedBox style={{ width: 'calc(100% / 3 - 30px)' }}>
              <DataList list={dopItems} />
            </BorderedBox>
          </Box>
          <TableBox
            title="Список продаж"
            filters={filters}
            isExpander
            defaultHidden
            column={COLUMN_SELL_FLOW}
            data={lastSell?.records || []}
            config={getSellsFlow}
          />
        </Box>

      </Content>
    </>
  );
};

export default observer(Dash);
