import { useState } from 'react';

import {
  Button,
  Divider,
  PageHeader, Tag, Typography,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { getStores } from 'configs';
import { observer } from 'mobx-react';
import StoreModal from 'pages/Orders/StoreModal';
import store from 'store/mainStore';
import sellStorage from 'store/sellStore';
import TableBox from 'components/TableBox/TableBox';
import Papa from 'papaparse';
import { saveToFile } from 'utils';
import ProductBox from 'components/ProductBox';

export const createName = (row: any) => {
  let name: string = row.product_name;
  if (row.model) name += ` / ${row.model}`;
  if (row.modification) name += ` / ${row.modification}`;
  if (row.size) name += ` / ${row.size}`;
  if (row.color) name += ` / ${row.color}`;
  return name;
};

const Stores = () => {
  const { addToSell } = sellStorage;
  const [storeOpen, setStoreOpen] = useState<boolean>(false);
  const [storeData, setStoreData] = useState<any>({});
  const [OLID, setOLID] = useState<number>(0);

  const { dictionaries, setUpdateSubscribe } = store;

  const storeOptions = (dictionaries?.stores || [])
    .filter((el: any) => ['work'].includes(el.type))
    .map((el: any) => ({ label: el.name, value: el.id, search: el.name })) || [];

  const StoreToogle = (order_list_id: number, sd: any = {}) => {
    setOLID(order_list_id);
    setStoreData(sd);
    if (storeOpen) setUpdateSubscribe('Список на складах');

    setStoreOpen(!storeOpen);
  };

  const COLUMN = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 80,
    },
    {
      title: 'Склад',
      dataIndex: 'store_name',
      ellipsis: true,
      width: 140,
      render: (row: any) => (
        <Tag>
          {row}
        </Tag>
      ),
    },
    {
      title: 'Товар',
      dataIndex: 'product_name',
      minWidth: 400,
      // ellipsis: true,
      render: (product_name: any, row: any) => (<ProductBox product_name={product_name} data={row} />),
    },
    /*  {
      title: 'Принято',
      dataIndex: 'date_put',
      ellipsis: true,
    }, */
    {
      title: 'Заказ',
      dataIndex: 'order_number',
      ellipsis: true,
      width: 100,
    },
    {
      title: 'Было/Прод/Рез/Ост',
      dataIndex: 'count',
      // ellipsis: true,
      render: (row: any, rows: any) => (
        <>
          <Typography.Text strong>{row || 0}</Typography.Text>
          <Divider type="vertical" />
          <Typography.Text strong type={rows.count_sell < 1 ? 'danger' : 'success'}>{rows.count_sell || 0}</Typography.Text>
          <Divider type="vertical" />
          <Typography.Text strong type="secondary">{rows.reserve}</Typography.Text>
          <Divider type="vertical" />
          <Typography.Text strong type={rows.stock < 1 ? 'danger' : 'success'}>{rows.stock}</Typography.Text>
        </>
      ),
    },
    {
      title: 'CC',
      dataIndex: 'pre_pice',
      ellipsis: true,
      width: 100,
      render: (r: number) => Number(r).toFixed(2),
    },
    {
      title: 'Переместить',
      dataIndex: 'last_update',
      ellipsis: true,
      render: (row: any, rows: any) => (<Button onClick={() => StoreToogle(rows.order_list_id, { from_id: rows.store_id, max: rows.stock })}>Переместить</Button>),
    },
    {
      title: 'Продать',
      dataIndex: 'last_update',
      minWidth: 70,
      ellipsis: true,
      render: (_: any, r: any) => (<Button type="primary" onClick={() => addToSell(r)}>Продать</Button>),
    },
  ];

  const filters = [
    {
      name: 'store',
      type: 'select',
      label: 'Склад',
      placeholder: 'Выберите склад',
      options: storeOptions,

    },
    {
      name: 'name',
      type: 'input',
      label: 'Наименование товара',
      placeholder: 'Введите наименование',
    },
  ];

  const downloadList = (list: any) => {
    const prepareObject = list.records.map((el: any) => ({
      Склад: el.store_name,
      Товар: createName(el),
      Заказ: el.order_number,
      Было: el.count,
      Прод: el.count_sell,
      Рез: el.reserve,
      Ост: el.stock,
      ИНВ: Number(el.stock) + Number(el.reserve),
    }));
    const fields = ['Склад', 'Товар', 'Заказ', 'Было', 'Прод', 'Рез', 'Ост', 'ИНВ'];
    const config: any = { delimiter: ';' };
    const csv2Export = Papa.unparse({ data: prepareObject, fields }, config);
    saveToFile(csv2Export, `Ивиентаризация_${new Date()}.csv`);
  };

  const getSummaryPrice = (list: any) => {
    console.log('list', list);
  };

  return (
    <>
      <PageHeader title="Склады" />
      <Content>

        <StoreModal isOpen={storeOpen} toogle={StoreToogle} order_list_id={OLID} getOrder={() => {}} notDelivery initValues={storeData} />
        <TableBox
          title="Список на складах"
          filters={filters}
          column={COLUMN}
          download={{
            func: downloadList,
            limit: 1000,
            title: 'Сохранить в файл',
          }}
          getFull={{
            func: getSummaryPrice,
            limit: 1000,
            title: 'Запросить суммарную стоимость',
          }}
          preapliedFilters={
            {
              stock: 1,
            }
          }
          config={getStores}
        />

      </Content>
    </>
  );
};

export default observer(Stores);

/*
// ATLAS (139) -> CROSSLAB MULTICAM (152)
// SELL FLOW
id 353 update order_list_id 662 -> 727 unit_id 214 -> 230

// STORE_LIST
id 214 update count_sell 6 - 2
id 230 update count_sell 2 + 2

//STORE FLOW
id 612 update order_list 662 -> 727

UPDATE sell_flow set `order_list_id` = 727, `unit_id` = 230 where id = 353;
UPDATE store_list set `count_sell` = 4 where id = 214;
UPDATE store_list set `count_sell` = 4 where id = 230;
UPDATE store_flow set `order_list_id` = 727 where id = 612;

 */
