import React, { useEffect } from 'react';

import {
  MenuProps, Layout, Menu, Typography,
} from 'antd';
import {
  UserOutlined, AuditOutlined,
} from '@ant-design/icons';
import {
  NavLink, Switch, useParams,
} from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';
import store from 'store/mainStore';
import { observer } from 'mobx-react';
import { getDict } from 'configs';
import useRequest from 'hooks/useRequest';
import { useMount } from 'hooks';
import { Box } from 'components/Box';
import HeaderMenu from 'components/header/Header';
import { ReactComponent as OzoneLogo } from 'assets/images/ozoneLogo.svg';

import Products from './Products/Products';
import Orders from './Orders/Orders';
import Balances from './Finance/Balances';
import StoreFlow from './Store/StoreFlow';
import Stores from './Store/Stores';
import CashFlow from './Finance/Flow';
import Sells from './Sells/Sells';
import SellFlow from './Sells/SellFlow';
import Sellers from './Products/Sellers';
import Dash from './Finance/Dash';
import ProductStats from './Products/ProductStats';
import OzonPage from './Ozone/OzonPage';
import OzoneProducts from './Ozone/OzoneProducts';

const minHeight = window.innerHeight;

const { Content, Header, Footer } = Layout;
type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}
const items = [
  getItem('Товары', 'p', <UserOutlined />, [
    /* getItem(
      <NavLink to="newProduct">
        Добавить товар
      </NavLink>,
      'newProduct',
    ), */
    getItem(
      <NavLink to="/dashboard/products">
        Список товаров
      </NavLink>,
      'products',
    ),
    getItem(
      <NavLink to="/dashboard/orders">
        Заказы
      </NavLink>,
      'orders',
    ),
    getItem(
      <NavLink to="/dashboard/sellers">
        Список производителей
      </NavLink>,
      'sellers',
    ),
    /*  getItem(
      <NavLink to="trash">
        Мусорник
      </NavLink>,
      'trash',
    ), */
  ]),
  /* getItem('Клиенты', 'clients', <UserOutlined />, [
    getItem(
      <NavLink to="users">
        Список Клиентов
      </NavLink>,
      'users',
    ),
    getItem(
      <NavLink to="client_report">
        Отчет по клиентам
      </NavLink>,
      'client_report',
    ),
  ]), */
  getItem('Склад', 'store', <AuditOutlined />, [
    getItem(
      <NavLink to="/dashboard/store-flow">
        Перемещения
      </NavLink>,
      'store-flow',
    ),
    getItem(
      <NavLink to="/dashboard/stores">
        Склады
      </NavLink>,
      'stores',
    ),
  ]),
  getItem('Продажи', 'sell', <AuditOutlined />, [
    getItem(
      <NavLink to="/dashboard/sells">
        Корзины
      </NavLink>,
      'sells',
    ),
    getItem(
      <NavLink to="/dashboard/sells-flow">
        Проданы
      </NavLink>,
      'sells-flow',
    ),
  ]),
  getItem('OZON', 'ozonsubs', <OzoneLogo style={{ height: '15px', width: '15px' }} />, [
    getItem(
      <NavLink to="/dashboard/ozon-products">
        Список товаров
      </NavLink>,
      'ozonProducts',
    ),
    getItem(
      <NavLink to="/dashboard/sells-ozon">
        Транзакции
      </NavLink>,
      'sells-ozon',
    ),
  ]),
  getItem('Финансы и отчеты', 'cashAndReports', <AuditOutlined />, [
    getItem(
      <NavLink to="/dashboard/balances">
        Балансы
      </NavLink>,
      'balances',
    ),
    getItem(
      <NavLink to="/dashboard/cash-flow">
        Переводы и оплаты
      </NavLink>,
      'cash-flow',
    ),
  ]),
  getItem('Аналитика', 'statistics', <AuditOutlined />, [
    getItem(
      <NavLink to="/dashboard/monitor">
        Монитор
      </NavLink>,
      'monitor',
    ),
  ]),
];
const Hub: React.FC = () => {
  const { isAuth, setDictionary } = store;
  const params: any = useParams();
  const { fetch, isSuccess, requestResult } = useRequest(getDict);

  useMount(() => fetch());

  useEffect(() => {
    if (isSuccess && requestResult) {
      setDictionary(requestResult);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, requestResult]);

  return (
    <Layout style={{ minHeight }}>
      <Header style={{ background: 'white' }}>
        <Box direction="row" width="100%" justify="space-between">
          <Box direction="row">
            <Box className="logoDk">
              DK
            </Box>
            <Box className="logoDelimeter">
              .
            </Box>
            <Box className="logoCRM">
              СЧЕТОВОД
            </Box>
          </Box>
          <Menu
            defaultSelectedKeys={['orders']}
          // defaultOpenKeys={['usersSubs', 'ordersSubs']}
            mode="horizontal"
            theme="light"
            style={{ minWidth: '60%' }}
            selectedKeys={[params.id]}
            items={items}
          />
          <HeaderMenu />
        </Box>

      </Header>
      <Content style={{ maxWidth: 1800, margin: '0 auto' }}>
        <Switch>
          <PrivateRoute path="/dashboard/product/:id" auth={isAuth}><ProductStats /></PrivateRoute>
          <PrivateRoute path="/dashboard/products" auth={isAuth}><Products /></PrivateRoute>
          <PrivateRoute path="/dashboard/orders" auth={isAuth}><Orders /></PrivateRoute>
          <PrivateRoute path="/dashboard/store-flow" auth={isAuth}><StoreFlow /></PrivateRoute>
          <PrivateRoute path="/dashboard/stores" auth={isAuth}><Stores /></PrivateRoute>
          <PrivateRoute path="/dashboard/sells" auth={isAuth}><Sells /></PrivateRoute>
          <PrivateRoute path="/dashboard/sells-ozon" auth={isAuth}><OzonPage /></PrivateRoute>
          <PrivateRoute path="/dashboard/ozon-products" auth={isAuth}><OzoneProducts /></PrivateRoute>
          <PrivateRoute path="/dashboard/sells-flow" auth={isAuth}><SellFlow /></PrivateRoute>
          <PrivateRoute path="/dashboard/balances" auth={isAuth}><Balances /></PrivateRoute>
          <PrivateRoute path="/dashboard/cash-flow" auth={isAuth}><CashFlow /></PrivateRoute>
          <PrivateRoute path="/dashboard/sellers" auth={isAuth}><Sellers /></PrivateRoute>
          <PrivateRoute path="/dashboard/monitor" auth={isAuth}><Dash /></PrivateRoute>
        </Switch>
      </Content>
      <Footer>
        <Box justify="center" width="100%" style={{ textAlign: 'center' }}>
          <Typography.Text type="secondary">
            Время действовать
          </Typography.Text>
        </Box>
      </Footer>
    </Layout>
  );
};

export default observer(Hub);
