export const geenStr = () => (Math.random() + 1).toString(36).substring(7);

export function getRandom(length: number) {
  return Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1));
}

export const balancesNames = [
  {
    label: 'Системные',
    value: 'system',
  },
  {
    label: 'Персональные',
    value: 'personal',
  },
  {
    label: 'Рабочие',
    value: 'work_balance',
  },
  {
    label: 'Доставка',
    value: 'delivery',
  },
  {
    label: 'Транзитные',
    value: 'transit',
  },
  {
    label: 'Продавцы',
    value: 'seller',
  },
  {
    label: 'Другие',
    value: 'expenses',
  },
  {
    label: 'Сотрудники',
    value: 'empolyee',
  },
];

export const getErr = (c: string) => {
  const code = Number(c);
  switch (code) {
    case 100:
      return 'ERROR_VERIFY_CONFIRMED';
    case 101:
      return 'ERROR_VERIFY_CONFIRMED';
    case 102:
      return 'ERROR_USER_ACCOUNT_COUNT_EXCEED';
    case 103:
      return 'ERROR_USER_NOT_VERIFIED_EMAIL';
    case 104:
      return 'ERROR_USER_EMAIL_NOT_EXISTS';
    case 105:
      return 'ERROR_EMAIL_UNIQUE';
    case 106:
      return 'ERROR_EMAIL_FORMAT_ERROR';
    case 107:
      return 'ERROR_NOT_EMPTY_DATA';
    case 108:
      return 'ERROR_NOT_NULL_DATA';
    case 109:
      return 'ERROR_FORMAT';
    case 110:
      return 'ERROR_PASSPORT_UNIQUE';
    case 111:
      return 'ERROR_UNIQUE_TIN';
    case 112:
      return 'ERROR_USER_MANDATORY_DATA';
    case 113:
      return 'ERROR_UNIQUE_PSRN';
    case 114:
      return 'ERROR_UNIQUE_COR';
    case 115:
      return 'ERROR_UNIQUE_INIPA';
    case 116:
      return 'ERROR_NULL_DATA';
    case 117:
      return 'ERROR_UNIQUE_PHONE';
    case 118:
      return 'ERROR_SIZE';
    case 119:
      return 'ERROR_LEGAL_AGE';
    case 120:
      return 'ERROR_DATE_PAST';
    case 121:
      return 'ERROR_MUST_TRUE';
    case 122:
      return 'ERROR_PASSWORD_MISMATCH';
    case 123:
      return 'ERROR_PASSWORD_ONE_MISSED';
    default:
      return 'Unknown error';
  }
};

const genUser = () => ({
  birthDate: '',
  confirmPassword: '',
  firstName: '',
  inipa: '',
  lastName: '',
  middleName: '',
  passport: '',
  passportIssuedBy: '',
  passportIssuedDate: '',
  password: '123123',
  phone: '',
  tin: '',
  user_cabin: '',
  user_city: '',
  user_index: '',
  user_home: '',
  user_street: '',
  /* birthDate: '',
  confirmPassword: '123123',
  firstName: geenStr(),
  inipa: `${getRandom(3)}-${getRandom(3)}-${getRandom(3)} ${getRandom(2)}`,
  lastName: geenStr(),
  middleName: geenStr(),
  passport: `${getRandom(2)} ${getRandom(2)} ${getRandom(6)}`,
  passportIssuedBy: `${getRandom(3)} ${geenStr()} ${geenStr()}`,
  passportIssuedDate: '12-12-2000',
  password: '123123',
  phone: `+7(${getRandom(3)})${getRandom(3)}-${getRandom(2)}-${getRandom(2)}`,
  tin: getRandom(10),
  user_cabin: getRandom(3),
  user_city: geenStr(),
  user_index: getRandom(6),
  user_home: getRandom(2),
  user_street: geenStr(), */
});

export const fixAddresLength = (str: string) => {
  if (!str) return '';
  const s = 5;
  const l = str.length - 7;
  let res = '';
  for (let i = 0; i < str.length; i++) {
    if (i < s || i > l) {
      res += str[i];
    } else if (!res.includes('...')) {
      res += '...';
    } else {
      continue;
    }
  }
  return res;
  // 0x06B7ed75E6acF0a9501979aAf74c216b98E36f09 42
};

export const getEllipsisTxt = (str: string, n = 6) => {
  if (str) {
    return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
  }
  return '';
};

export function saveToFile(content: any, filename: string) {
  const file = new Blob([content], { type: 'application/octet-stream' });

  const a = document.createElement('a');
  const url = URL.createObjectURL(file);
  a.href = url;
  a.download = filename;
  document.body.append(a);
  a.click();
  setTimeout(() => {
    a.remove();
    window.URL.revokeObjectURL(url);
  }, 0);
}

export const sorter = (array: any[]) => array.sort((a, b) => a.name - b.name);

export default genUser;
