import { Route, Redirect } from 'react-router-dom';

interface Props {
    children: JSX.Element;
    auth: boolean;
    path: string;
    key?: any;

}

const PrivateRoute = ({ children, auth, path, key }: Props) => (
  <Route key={key} path={path} render={() => (!auth ? <Redirect to="/signin" /> : children)} />
);
export default PrivateRoute;
