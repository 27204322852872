/* eslint-disable max-len */
import {
  computed,
  decorate, observable, toJS,
} from 'mobx';
import LocalStorage from 'utils/LocalStorage';
import { v4 as uuid } from 'uuid';

class Store {
    sellStore: any = LocalStorage.get('sellStore') ? JSON.parse(LocalStorage.get('sellStore')) : [];

    sellModalOpen: boolean = false;

    sellData: any = JSON.parse(LocalStorage.get('sellState')) || {
      state: '9',
      comment: '',
      delivery_our: 0,
      delivery_client: 0,
      gift_by: 0,
      payment_services: '2',
    }

    get getSelData() {
      return toJS(this.sellData);
    }

    get sellList() {
      if (Object.keys(this.sellStore).length) {
        return toJS(this.sellStore);
      }
      return [];
    }

    get isCredit() {
      let a: boolean = false;
      const array = toJS(this.sellStore);
      for (let index = 0; index < array.length; index++) {
        const isCr = Number(array[index].unit_price) * Number(array[index].count_sell) - Number(array[index].pay_sum);
        if (isCr > 0) {
          a = true;
        }
      }
      return a;
    }

    get isUnSaved() {
      return toJS(this.sellStore).filter((el: any) => !!el.isAdded).length > 0;
    }

    sellModalHandler = () => this.sellModalOpen = !this.sellModalOpen

    addToSell = (product: any) => {
      const oldStore = toJS(this.sellStore);
      const prod = { ...product };
      delete prod.id;
      oldStore.push({
        ...prod,
        unique_id: uuid(),
        isAdded: true,
        count: 1,
        pay_sum: 0,
        unit_id: product.id,
        unit_price: product.rec_price || 0,
        state: '9',
        comment: '',
      });
      this.sellStore = oldStore;
      LocalStorage.set('sellStore', JSON.stringify(toJS(this.sellStore)));
    }

    removeFromSellStore = (unique_id: string) => {
      this.sellStore = toJS(this.sellStore).filter((el: any) => el.unique_id !== unique_id);
    }

    fillStore = (main: any) => {
      const mainCreate = {
        id: main.id,
        state: main.state,
        comment: main.comment,
        delivery_our: main.delivery_our,
        delivery_client: main.delivery_client,
        gift_by: main.gift_by,
        seller: Number(main.seller),
        place: main.place,
        amount_card: Number(main.amount_card),
        date_created: main.date_created,
        tax: main.tax,
        payment_services: main.payment_services,
        delivery_payer: main.delivery_payer,
        invoice: main.invoice,
        payment_id: main.payment_id,
        RRN: main.RRN,
        payment_tax: main.payment_tax,
        client_identy: main.client_identy,
      };
      this.sellData = mainCreate;
      this.sellModalOpen = true;
    }

    fillList = (list: any[]) => {
      const newList = list.map((el: any) => ({ ...el, unique_id: uuid() }));
      this.sellStore = newList;
    }

    dropSellStore = () => {
      this.sellStore = [];
      this.sellData = {
        state: '9',
        comment: '',
        delivery_our: 0,
        delivery_client: 0,
        gift_by: 0,
      };
      this.sellModalOpen = false;
      LocalStorage.remove('sellStore');
      LocalStorage.remove('sellState');
    }

    countHandler = (unique_id: number, count: number) => {
      const index = this.sellList.findIndex((x: any) => x.unique_id === unique_id);
      const o = this.sellStore;
      o[index].count_sell = Number(count);
      this.sellStore = o;
    };

    paySumHandler = (unique_id: number, count: number) => {
      const index = this.sellList.findIndex((x: any) => x.unique_id === unique_id);
      const o = this.sellStore;
      o[index].pay_sum = Number(count);
      this.sellStore = o;
    };

    unitPriceHandler = (unique_id: number, count: number) => {
      const index = this.sellList.findIndex((x: any) => x.unique_id === unique_id);
      const o = this.sellStore;
      o[index].unit_price = Number(count);
      this.sellStore = o;
    };

    stateHandler = (unique_id: number, state: any) => {
      const index = this.sellList.findIndex((x: any) => x.unique_id === unique_id);
      const o = this.sellStore;
      o[index].state = state;
      this.sellStore = o;
    };

    handler = (key: string, value: any) => {
      const d = { ...this.sellData };
      d[key] = value;
      this.sellData = { ...d };
      LocalStorage.set('sellState', JSON.stringify(toJS(d)));
    };

    handlerList = (value: any) => {
      const d = { ...this.sellData };
      const nv = { ...d, ...value };
      this.sellData = { ...nv };
      LocalStorage.set('sellState', JSON.stringify(toJS(nv)));
    };
}

decorate(Store, {
  sellStore: observable,
  sellModalOpen: observable,
  sellData: observable,
  getSelData: computed,
  sellList: computed,
  isUnSaved: computed,
  isCredit: computed,
});

const sellStorage = new Store();

export default sellStorage;
