import { useEffect } from 'react';

import {
  Button, DatePicker, Form, Input, message, Modal, Select,
} from 'antd';
import {
  storeTransfer,
  storeTransferDelivery,
} from 'configs';
import useRequest from 'hooks/useRequest';
import store from 'store/mainStore';
import { observer } from 'mobx-react';
import moment from 'moment';

type Props = {
    toogle: any;
    isOpen: boolean;
    // order: any;
    // product: any;
    getOrder: any;
    notDelivery?: boolean;
    initValues?: any;
    order_list_id: number;
}

const StoreModal = ({
  isOpen, toogle, getOrder, notDelivery, initValues, order_list_id,
}: Props) => {
  const { fetch, isSuccess, isLoading, dropState } = useRequest(notDelivery ? storeTransfer : storeTransferDelivery);

  const { userData, dictionaries } = store;
  const onFinish = (value: any) => {
    if (initValues?.max && Number(initValues.max) < Number(value.amount)) {
      message.error(`Не разгоняйся, столько там нет, максимум ${initValues.max}`);
      return;
    }
    fetch({
      ...value,
      order_list_id,
      author: `${userData.id}`,
      date_transfer: moment(value.date_transfer).format('YYYY-MM-DD hh:mm:ss'),
    });
  };

  useEffect(() => {
    if (!isSuccess) return;

    dropState();
    getOrder();
    toogle(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const storeOptions = (dictionaries?.stores || [])
    .filter((el: any) => ['work', 'delivery'].includes(el.type))
    .map((el: any) => ({ label: el.name, value: el.id })) || [];

  return (
    <Modal
      visible={isOpen}
      destroyOnClose
      title="Переместить товар на склад"
      footer={false}
      style={{ width: '420px' }}
      width="450px"
      zIndex={100}
      onCancel={() => toogle(false)}
      bodyStyle={{ width: '420px' }}
    >
      <Form
        name="store"
        onFinish={onFinish}
        layout="inline"
        initialValues={{ author: `${userData.id}`, ...initValues }}
      >
        <Form.Item name="from_id" label="Выберите склад">
          <Select
            style={{ width: '400px' }}
            options={storeOptions}
            disabled={notDelivery}
          />
        </Form.Item>
        <Form.Item name="amount" label="Количество" rules={[{ required: true }]}>
          <Input style={{ width: '400px' }} type="number" />
        </Form.Item>
        <Form.Item name="to_id" label="Выберите склад">
          <Select
            style={{ width: '400px' }}
            options={storeOptions}
          />
        </Form.Item>
        <Form.Item name="date_transfer">
          <DatePicker />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '400px' }} loading={isLoading}>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(StoreModal);
