import { useEffect, useState } from 'react';

import {
  Button, DatePicker, Form, Input, Modal, Select, Typography,
} from 'antd';
import {
  getPersonalBalances,
  makeBalanceTransaction,
} from 'configs';
import useRequest from 'hooks/useRequest';
import store, { StateData } from 'store/mainStore';
import { observer } from 'mobx-react';
import { useMount } from 'hooks';
import moment from 'moment';
import { balancesNames } from 'utils';
import { Box } from 'components/Box';
import Filter from 'components/Filter/Filter';

type Props = {
    toogle: any;
    isOpen: boolean;
    availableFrom?: string[];
    availableTo?: string[];
    initValues?: any;
}

const TransferModal = ({ isOpen, toogle, availableFrom = ['personal', 'transit', 'system'], availableTo = [], initValues = {} }: Props) => {
  const { dictionaries, curr } = store;
  const { fetch, isSuccess, isLoading, dropState } = useRequest(makeBalanceTransaction);
  const { fetch: fetchBalances, requestResult: balances, isLoading: isLoadingBalances } = useRequest(getPersonalBalances);

  const { userData } = store;
  const [from, setFrom] = useState<any>({});
  const [fromFiltred, setFromFiltred] = useState<string[]>(availableFrom);
  const [toFiltred, settoFiltred] = useState<string[]>(availableTo);
  const [to, setTo] = useState<any>({});
  const [rate, setRate] = useState<any>(0);
  const [amount, setAmount] = useState<any>(initValues.amount || 0);

  const onFinish = (value: any) => {
    const body = {
      from_id: from.data.id,
      to_id: to.data.id,
      from_curr: from.data.currency,
      to_curr: to.data.currency,
      rate,
      amount: value.amount,
      type: value.type,
      tags: initValues?.tags,
      author: `${userData.id}`,
      comment: value.comment,
      payment_date: moment(value.payment_date).format('YYYY-MM-DD hh:mm:ss'),
    };
    fetch(body);
  };

  const fromhandler = (_: any, option: any) => {
    setFrom(option);
  };

  const tohandler = (_: any, option: any) => {
    setTo(option);
  };

  useMount(() => {
    fetchBalances();
  });

  useEffect(() => {
    if (!isSuccess) return;
    setFromFiltred(availableFrom);
    settoFiltred(availableTo);
    dropState();
    toogle(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (!to.data) return;
    setRate(from.data.currency === 'RUB' && to.data.currency === 'CNY' ? Number(curr.cny.toFixed(2)) : 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [to]);

  const balansesFromDefault = balancesNames.filter((el: any) => availableFrom.includes(el.value));
  const balansesToDefault = balancesNames.filter((el: any) => availableTo.includes(el.value));

  const FROM_OPTIONS = balances?.records
    .filter((el: any) => fromFiltred.includes(el.type)) // TODO ПРоверка на путсой баланс
    .map((el: any) => ({ label: `${el.name} - ${el.amount} ${el.currency}`, value: el.id, data: el }));

  const TO_OPTIONS = balances?.records
    .filter((el: any) => el.id !== from.data?.id && (availableTo.length ? toFiltred.includes(el.type) : true))
    .map((el: any) => ({ label: `${el.name} - ${el.amount} ${el.currency}`, value: el.id, data: el }));

  return (
    <Modal
      visible={isOpen}
      destroyOnClose
      title="Создать перевод"
      footer={false}
      style={{ width: '420px' }}
      width="450px"
      zIndex={900}
      onCancel={() => toogle(false)}
      bodyStyle={{ width: '420px' }}
    >
      <Form
        name="product_form"
        onFinish={onFinish}
        layout="inline"
        initialValues={{ ...initValues, author: `${userData.id}` }}
      >
        <Form.Item
          name="from_id"
          label="С баланса"
          required
          extra={(
            <Box direction="row" justify="flex-end">
              <Filter data={balansesFromDefault} onChange={setFromFiltred} defaultValue={availableFrom} />
            </Box>
        )}
        >
          <Select
            style={{ width: '400px' }}
            onChange={fromhandler}
            showSearch
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            loading={isLoadingBalances}
            options={FROM_OPTIONS}
          />
        </Form.Item>
        <Form.Item
          name="to_id"
          label="На баланс"
          required
          extra={(
            <Box direction="row" justify="flex-end">
              <Filter data={balansesToDefault} onChange={settoFiltred} defaultValue={availableTo} />
            </Box>
        )}
        >
          <Select
            style={{ width: '400px' }}
            onChange={tohandler}
            showSearch
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            loading={isLoadingBalances}
            disabled={!from.data?.id}
            options={TO_OPTIONS}
          />
        </Form.Item>
        <Form.Item name="amount" label="Сумма" rules={[{ required: true }]}>
          <Input style={{ width: '400px' }} disabled={!from.data?.id} value={amount} onChange={(e:any) => setAmount(e.target.value)} />
        </Form.Item>
        <Form.Item name="rate" label="Курс" rules={[{ required: true }]}>
          <Input style={{ width: '400px' }} value={rate} disabled={!to.data?.id} onChange={(e:any) => setRate(e.target.value)} />
        </Form.Item>
        <Typography.Text>{`Баланс увеличится на ${(amount / rate).toFixed(2)} ${to.data?.currency}`}</Typography.Text>
        <Form.Item name="type" label="Тип операции">
          <Select
            style={{ width: '400px' }}
            loading={isLoadingBalances}
            options={dictionaries?.payment_types?.map((el: StateData) => ({ label: el.name, value: el.id }))}
          />
        </Form.Item>
        <Form.Item name="payment_date">
          <DatePicker />
        </Form.Item>
        <Form.Item name="comment" label="Комментарий" required>
          <Input.TextArea style={{ width: '400px' }} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '400px' }} loading={isLoading}>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(TransferModal);
