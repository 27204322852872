import { useEffect } from 'react';

import {
  Button, DatePicker, Form, Input, Modal, Select,
} from 'antd';
import {
  makeBalanceTransactionInvest,
} from 'configs';
import useRequest from 'hooks/useRequest';
import store from 'store/mainStore';
import { observer } from 'mobx-react';

type Props = {
    toogle: any;
    isOpen: boolean;
    balances: any;
}

const CreatePayementInvestModal = ({ isOpen, toogle, balances }: Props) => {
  const { fetch, isSuccess, isLoading, dropState } = useRequest(makeBalanceTransactionInvest);
  const { userData } = store;

  const onFinish = (value: any) => {
    fetch({ ...value, author: `${userData.id}` });
  };

  useEffect(() => {
    if (!isSuccess) return;
    dropState();
    toogle(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Modal
      visible={isOpen}
      destroyOnClose
      title="Создать инвестицию"
      footer={false}
      style={{ width: '420px' }}
      width="450px"
      zIndex={100}
      onCancel={() => toogle(false)}
      bodyStyle={{ width: '420px' }}
    >
      <Form
        name="product_form"
        onFinish={onFinish}
        layout="inline"
        initialValues={{ author: `${userData.id}` }}
      >
        <Form.Item name="to_id" label="На баланс">
          <Select
            style={{ width: '400px' }}
            options={balances?.records.filter((el: any) => el.type === 'personal').map((el: any) => ({ label: el.name, value: el.id }))}
          />
        </Form.Item>
        <Form.Item name="amount" label="Сумма" rules={[{ required: true }]}>
          <Input style={{ width: '400px' }} />
        </Form.Item>
        <Form.Item name="payment_date" rules={[{ required: true }]} label="Дата операции">
          <DatePicker />
        </Form.Item>
        <Form.Item name="comment" label="Комментарий">
          <Input.TextArea style={{ width: '400px' }} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '400px' }} loading={isLoading}>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(CreatePayementInvestModal);
