import { CSSProperties } from 'react';

import { Divider, Typography } from 'antd';
import { Box } from 'components/Box';

type Props = {
    title?: string;
    children: JSX.Element | string;
    top?: number;
    style?: CSSProperties;
}

const BorderedBox = ({ title, children, top, style = {} }: Props) => (
  <Box className="borderedBox" margin={[top || 0, 0, 0, 0]} style={style}>
    {title ? (
      <>
        <Box padding={[2, 2]}>
          <Typography.Title level={4}>{title}</Typography.Title>
        </Box>
        <Divider />
      </>
    ) : null}
    <Box padding={[2, 2]}>
      {children}
    </Box>
  </Box>
);

export default BorderedBox;
