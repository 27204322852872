import { useEffect, useState } from 'react';

import {
  Alert,
  Button, Divider, Input, Modal, PageHeader, Table,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import useRequest from 'hooks/useRequest';
import { getDict, createSeller } from 'configs';
import { useMount } from 'hooks';
import { observer } from 'mobx-react';
import { Box } from 'components/Box';
import store from 'store/mainStore';

const Sellers = () => {
  const { setDictionary } = store;
  const { fetch, requestResult, isLoading, isSuccess: suss } = useRequest(getDict);
  const { fetch: createFetch, isSuccess } = useRequest(createSeller);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [fab, setFab] = useState<string>('');

  const COLUMN = [
    {
      title: 'id',
      dataIndex: 'id',
      ellipsis: true,
      width: 50,
    },
    {
      title: 'Название',
      dataIndex: 'name',
      ellipsis: true,
    },

  ];

  const handler = () => {
    createFetch({
      name: fab,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      fetch();
      setIsOpen(false);
      setFab('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (suss && requestResult) {
      setDictionary(requestResult);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suss]);

  useMount(() => {
    fetch();
  });

  return (
    <>
      <PageHeader title="Продавцы" extra={<Button type="primary" onClick={() => setIsOpen(true)} icon={<PlusCircleOutlined />}>Добавить фабрику </Button>} />
      <Modal
        visible={isOpen}
        destroyOnClose
        title="Новый продавец"
        zIndex={100}
        onOk={handler}
        onCancel={() => { setIsOpen(false); setFab(''); }}
      >
        <Box>
          <Alert message='Префикс "Фабрика" будет добавлен автоматически' type="warning" showIcon />
          <Divider />
          <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginBottom: '20px' }}>
            <label style={{ marginBottom: '7px' }}>
              Название
            </label>
            <Input style={{ width: '100%' }} placeholder="Введите название фабрики" value={fab} onChange={e => setFab(e.target.value)} />
          </div>
        </Box>
      </Modal>
      <Content>
        <Table
          columns={COLUMN}
          dataSource={requestResult?.sellers || []}
          style={{ maxWidth: '100%' }}
          size="small"
          loading={isLoading}
          pagination={false}
        />
      </Content>
    </>
  );
};
export default observer(Sellers);
