import { useEffect, useState } from 'react';

import store from 'store/mainStore';
import { observer } from 'mobx-react-lite';
import { ReactComponent as Avatar } from 'assets/images/avatarDefault.svg';
import { ShoppingOutlined } from '@ant-design/icons';
import {
  Badge, Button, Popover, Typography,
} from 'antd';
import { Box } from 'components/Box';
import sellStorage from 'store/sellStore';
import useRequest from 'hooks/useRequest';
import { getCurr } from 'configs';
import { useMount } from 'hooks';

import './style.scss';

import SellModal from './SellModal';

const HeaderMenu = () => {
  const {
    isAuth, userData, logout, setCurr, curr,
  } = store;

  const { fetch, requestResult } = useRequest(getCurr);

  const { sellStore, sellModalHandler } = sellStorage;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [isOpenChangePassword, setIsOpenChangePassword] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const toogleModal = () => setIsOpen(!isOpen);
  // const toogleModalPassword = () => setIsOpenChangePassword(!isOpenChangePassword);

  const clickOpen = () => {
    toogleModal();
    setVisible(false);
  };

  useMount(fetch);

  useEffect(() => {
    if (!requestResult) return;
    setCurr(requestResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestResult]);

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  return (
    <>
      <SellModal />
      <div className="header">
        <div className="header_menu">
          <Box direction="row" align="center">
            <Box margin={[0, 2]}>
              <Typography.Text type="secondary">
                {`CNY: ${curr.cny.toFixed(2)}`}
              </Typography.Text>
            </Box>
            <Box>
              <Typography.Text type="secondary">
                {`USD: ${curr.usd.toFixed(2)}`}
              </Typography.Text>
            </Box>
          </Box>
          <Box margin={[2, 0]}>
            <Badge count={sellStore.length}>
              <Box align="center" justify="center">
                <Button type="primary" icon={<ShoppingOutlined />} onClick={sellModalHandler} disabled={sellStore.length < 1}>Продажа</Button>
              </Box>
            </Badge>
          </Box>
          { isAuth && (
            <Box margin={[2, 0]}>
              <Popover
                visible={visible}
                trigger="click"
                content={(
                  <div className="accountMenu">
                    <span className="accountMenu_name">{userData.name}</span>
                    <span onClick={clickOpen}>Мой профиль</span>
                    {/* <span onClick={clickOpenPassword}>{change_password}</span> */}
                    <div className="divider" />
                    <span onClick={logout}>Выйти</span>
                  </div>
)}
                onVisibleChange={handleVisibleChange}
              >
                <Avatar onClick={() => setVisible(!visible)} style={{ cursor: 'pointer' }} />
              </Popover>
            </Box>
          )}
        </div>

      </div>

    </>
  );
};
export default observer(HeaderMenu);
