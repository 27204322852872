import { useEffect, useRef } from 'react';

import { observer } from 'mobx-react';
import store from 'store/mainStore';
import Auth from 'pages/Auth';
import './assets/main.scss';
import 'antd/dist/antd.css';
import Hub from 'pages/Hub';
import PrivateRoute from 'components/PrivateRoute';
import { Route, Redirect, Switch } from 'react-router-dom';

const App = () => {
  const {
    isAuth, screenWidth, setScreenWidth,
  } = store;
  const wrapperRef = useRef<undefined | any>();

  useEffect(() => {
    const resize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [setScreenWidth]);

  useEffect(() => {
    if (!wrapperRef?.current) return;
    wrapperRef.current.style.width = `${screenWidth - 30}px`;
  }, [screenWidth]);

  return (
    <>
      <Switch>
        <Route path="/signin">{isAuth ? <Redirect to="/dashboard/products" /> : <Auth />}</Route>
        <PrivateRoute path="/dashboard/:id" auth={isAuth}><Hub /></PrivateRoute>
        <PrivateRoute path="/dashboard/" auth={isAuth}>
          {isAuth ? <Redirect to="/dashboard/products" /> : <Redirect to="/signin" />}
        </PrivateRoute>
        <Route exact path="/">
          {isAuth ? <Redirect to="/dashboard" /> : <Redirect to="/signin" />}
        </Route>
        <Route exact path="*">
          <Redirect to="/" />
        </Route>
      </Switch>

    </>
  );
};

export default observer(App);
