import { useEffect, useState } from 'react';

import {
  Alert,
  Button, DatePicker, Divider, Input, Modal, Select, Switch, Table, Typography,
} from 'antd';
import {
  DeleteOutlined, DollarOutlined, ExportOutlined, CloseCircleOutlined, CheckCircleOutlined,
} from '@ant-design/icons';
import { Box } from 'components/Box';
import {
  getPersonalBalances, createSellEp, getSellsFlow, updateSell, pushSell, pushPreSell, getInvoices, getRecipient,
} from 'configs';
import { useMount } from 'hooks';
import useRequest from 'hooks/useRequest';
import { observer } from 'mobx-react';
import store, { StateData } from 'store/mainStore';
import sellStorage from 'store/sellStore';
import { sorter } from 'utils';
import moment, { Moment } from 'moment';
import BorderedBox from 'components/BorderedBox/BorderedBox';
import DataList from 'components/BorderedBox/DataList';
import ProductBox from 'components/ProductBox';

const SellModal = () => {
  const {
    dictionaries, userData, setUpdateSubscribe,
  } = store;

  const {
    sellModalOpen,
    sellModalHandler,
    handlerList,
    isUnSaved,
    isCredit,
    removeFromSellStore,
    fillList,
    sellList,
    getSelData,
    countHandler,
    stateHandler,
    paySumHandler,
    unitPriceHandler,
    handler,
    dropSellStore,
  } = sellStorage;

  const [isGift, setIsGift] = useState<boolean>(false);
  const [paymentDate, setPaymentDate] = useState<Moment>();
  const { fetch, requestResult: balances } = useRequest(getPersonalBalances);
  const { fetch: pushSellFetch, isLoading: loadingPushSell, isSuccess: isSuccessPushSell } = useRequest(pushSell);
  const { fetch: pushPreSellFetch, isLoading: loadingPushPreSell, isSuccess: isSuccessPushPreSell } = useRequest(pushPreSell);
  const { fetch: fetchFlow, requestResult: flow, isLoading: isLoadingLow, dropState: dropFlow } = useRequest(getSellsFlow);
  const { fetch: fetchCreateSell, isSuccess, dropState } = useRequest(createSellEp);
  const { fetch: fetchInvoice, requestResult: invoices } = useRequest(getInvoices);
  const { fetch: fetchRecipts, requestResult: recipts } = useRequest(getRecipient);

  const { fetch: fetchUpdateSell, isSuccess: isSuccessUpdate, dropState: dsUpdate } = useRequest(updateSell);
  const [addInvoice, setAddInvoice] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<string>('0');
  const remove = (unique_id: string) => {
    removeFromSellStore(unique_id);
  };

  useMount(() => fetch());

  useEffect(() => {
    fetchInvoice({}, {
      status: 'succeeded',
      limit: 100,
      // created_at: '2023-04-18T15:26:54.241Z',
    });
  }, [getSelData, fetchInvoice]);

  const invoicesOptions = invoices?.items?.map((el: any) => ({ label: `${el.metadata.customerNumber} (${el.amount.value} руб.) ${el.metadata.orderNumber}`, value: el.id, data: el }));

  useEffect(() => {
    if (invoice === '0') return;
    fetchRecipts({}, {
      receipts_id: invoice,
    });
  }, [fetchRecipts, invoice]);

  const pushSellhandler = () => {
    const values = { ...getSelData };
    const state = isGift ? 3 : values.state;
    const sellListCleaned = sellList.map((el: any) => ({
      count_sell: el.count_sell,
      id: el.id,
      unit_id: el.unit_id,
      store_id: el.store_id,
      product_id: el.product_id,
      order_id: el.order_id,
      author: `${userData.id}`,
      order_list_id: el.order_list_id,
    }));
    const o = {
      ...(values.id && { id: values.id }),
      state,
      author: `${userData.id}`,
      payment_date: moment(paymentDate).format('YYYY-MM-DD HH:mm:ss'),
      comment: `Продажа по корзине DKS-${values.id}`,
      seller: values.seller,
      delivery_our: values.delivery_our || 0,
      client_identy: values.client_identy || '',
      product_list: sellListCleaned,
      amount_card: values.amount_card,
      tax: values.tax || 0,
      delivery_payer: values.delivery_payer || null,
      payment_services: values.payment_services || 3,
      payment_balance_id: dictionaries?.payment_services.find((el: any) => el.id === values.payment_services)?.balance_id || 0,
      invoice: values.invoice || '',
      payment_id: values.payment_id || '',
      RRN: values.RRN || '',
      payment_tax: values.payment_tax || 0,
    };

    pushSellFetch(o);
  };

  const pushPreSellhandler = () => {
    const values = { ...getSelData };
    const state = isGift ? 3 : values.state;
    const sellListCleaned = sellList.map((el: any) => ({
      count_sell: el.count_sell,
      id: el.id,
      unit_id: el.unit_id,
      store_id: el.store_id,
      product_id: el.product_id,
      order_id: el.order_id,
      author: `${userData.id}`,
      order_list_id: el.order_list_id,
    }));
    const o = {
      ...(values.id && { id: values.id }),
      state,
      author: `${userData.id}`,
      payment_date: moment(paymentDate).format('YYYY-MM-DD HH:mm:ss'),
      comment: `Продажа по корзине DKS-${values.id}`,
      seller: values.seller,
      delivery_our: values.delivery_our || 0,
      client_identy: values.client_identy || '',
      product_list: sellListCleaned,
      amount_card: values.amount_card,
      tax: values.tax || 0,
      delivery_payer: values.delivery_payer || null,
      payment_services: values.payment_services || 3,
      payment_balance_id: dictionaries?.payment_services.find((el: any) => el.id === values.payment_services)?.balance_id || 0,
      invoice: values.invoice || '',
      payment_id: values.payment_id || '',
      RRN: values.RRN || '',
      payment_tax: values.payment_tax || 0,
    };

    pushPreSellFetch(o);
  };

  const createSell = () => {
    const values = { ...getSelData };
    const state = isGift ? 3 : values.state;
    const sellListCleaned = sellList.map((el: any) => ({
      id: el.id,
      sell_id: el.sell_id,
      product_id: el.product_id,
      order_id: el.order_id,
      count_sell: el.count_sell,
      date_created: moment(values.date_created).format('YYYY-MM-DD HH:mm:ss'),
      seller: values.seller,
      pay_sum: el.pay_sum,
      unit_price: el.unit_price,
      state: el.state,
      comment: el.comment,
      store_id: el.store_id,
      unit_id: el.unit_id,
      author: `${userData.id}`,
      gift_by: isGift ? values.gift_by : 0,
      order_list_id: el.order_list_id,
    }));
    const o = {
      ...(values.id && { id: values.id }),
      state,
      author: `${userData.id}`,
      date_created: moment(values.date_created).format('YYYY-MM-DD HH:mm:ss'),
      comment: values.comment || '',
      seller: values.seller,
      delivery_our: values.delivery_our || 0,
      delivery_client: values.delivery_client || 0,
      place: values.place,
      gift_by: isGift ? values.gift_by : 0,
      product_list: sellListCleaned,
      tax: values.tax || 0,
      client_identy: values.client_identy || '',
      delivery_payer: values.delivery_payer || null,
      payment_services: values.payment_services || '3',
      payment_balance_id: dictionaries?.payment_services.find((el: any) => el.id === values.payment_services)?.balance_id || 0,
      invoice: values.invoice || '',
      payment_id: values.payment_id || '',
      RRN: values.RRN || '',
      payment_tax: values.payment_tax || 0,
    };

    if (values.id) {
      fetchUpdateSell(o);
    } else {
      fetchCreateSell(o);
    }
  };

  const closeHandler = () => {
    sellModalHandler();
    dropFlow();
  };

  useEffect(() => {
    if (getSelData?.id && !flow) {
      fetchFlow({}, {
        sell_id: getSelData?.id,
        perPage: 1000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSelData]);

  useEffect(() => {
    if (flow) {
      fillList(flow.records);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow]);

  useEffect(() => {
    if (!isSuccess) return;
    sellModalHandler();
    dropState();
    dropSellStore();
    dropFlow();
    setUpdateSubscribe('Список корзин');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (!isSuccessPushSell) return;
    sellModalHandler();
    dropState();
    dropSellStore();
    dsUpdate();
    setUpdateSubscribe('Список корзин');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessPushSell]);

  useEffect(() => {
    if (!isSuccessPushPreSell) return;
    sellModalHandler();
    dropState();
    dropSellStore();
    dsUpdate();
    setUpdateSubscribe('Список корзин');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessPushPreSell]);

  useEffect(() => {
    if (!isSuccessUpdate) return;
    sellModalHandler();
    dropState();
    dropSellStore();
    dsUpdate();
    setUpdateSubscribe('Список корзин');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdate]);

  const COLUMN = [
    {
      title: 'Склад',
      dataIndex: 'store_name',
      ellipsis: true,
    },
    {
      title: 'Товар',
      dataIndex: 'product_name',
      ellipsis: true,
      minWidth: 400,
      render: (product_name: any, row: any) => (<ProductBox product_name={product_name} data={row} />),
    },
    {
      title: 'На складе',
      dataIndex: 'stock',
      ellipsis: true,
      width: 100,
    },
    {
      title: 'Кол-во',
      dataIndex: 'stock',
      ellipsis: true,
      render: (stock: any, row: any) => (
        <Box width={50}>
          <Input
            type="number"
            disabled={getSelData.state === '1'}
            value={row.count_sell}
            max={stock}
            onChange={(e: any) => countHandler(row.unique_id, e.target.value)}
            status={row.count_sell > stock ? 'error' : ''}
          />
        </Box>
      ),
    },
    {
      title: 'Цена (ед)',
      dataIndex: 'stock',
      ellipsis: true,
      render: (_: any, row: any) => (
        <Box width={100}>
          <Input
            type="number"
            disabled={getSelData.state === '1'}
            value={row.unit_price}
            max={row}
            onChange={(e: any) => unitPriceHandler(row.unique_id, e.target.value)}
          />
        </Box>
      ),
    },
    {
      title: 'Сумма',
      dataIndex: 'stock',
      ellipsis: true,
      width: 100,
      render: (_: any, row: any) => (
        <Box direction="row" align="center" justify="space-between">
          <Typography.Text type="secondary" onClick={() => paySumHandler(row.unique_id, row.count_sell * row.unit_price)}>{`${row.count_sell * row.unit_price} руб`}</Typography.Text>
        </Box>
      ),
    },
    {
      title: 'Оплачено',
      dataIndex: 'stock',
      ellipsis: true,
      render: (stock: any, row: any) => (
        <Box width={80}>
          <Input
            type="number"
            disabled={getSelData.state === '1'}
            value={row.pay_sum}
            max={row}
            onChange={(e: any) => paySumHandler(row.unique_id, e.target.value)}
          />
        </Box>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'state',
      ellipsis: true,
      render: (r: any, row: any) => (
        <Select
          style={{ width: '150px' }}
          onChange={(v: any) => stateHandler(row.unique_id, v)}
          placeholder="Выбирите статус"
          value={r}
          disabled={getSelData.state === '1'}
          options={dictionaries?.sale_type?.map((el: StateData) => ({ label: el.name, value: el.id }))}
        />
      ),
    },
    {
      title: ' ',
      dataIndex: 'unique_id',
      ellipsis: true,
      width: 50,
      render: (unique_id: any, row: any) => (row.isAdded ? (
        <Box width={50}>
          <Button icon={<DeleteOutlined />} onClick={() => remove(unique_id)} />
        </Box>
      ) : <></>),
    },
  ];

  const invoiceHandle = () => {
    const selectedInvoice = invoices.items.find((el: any) => el.id === invoice);
    const { metadata, authorization_details, amount, income_amount } = selectedInvoice;
    handlerList({
      invoice: metadata.dashboardInvoiceOriginalNumber,
      payment_id: invoice,
      RRN: authorization_details?.rrn || invoice,
      payment_tax: Number(amount.value) - Number(income_amount.value),
      client_identy: metadata.customerNumber,
    });
    setAddInvoice(false);
  };

  const invoiceHandleCancel = () => {
    handlerList({
      invoice: '',
      payment_id: '',
      RRN: '',
      payment_tax: 0,
    });
    setInvoice('0');
  };

  return (
    <Modal
      visible={sellModalOpen}
      destroyOnClose
      title={(
        <Box direction="row" align="center">
          <Typography.Text>
            Создать продажу
          </Typography.Text>
          <Box width={30} />
          <Switch onChange={() => setIsGift(!isGift)} checked={isGift} checkedChildren="Списание себе" unCheckedChildren="Продажа клиенту" />
          <Box width={30} />
          <Button onClick={dropSellStore}>Сбросить</Button>
          <Box width={30} />
          <Button
            type="primary"
            disabled={isCredit || ['1', '2'].includes(getSelData.state)}
            onClick={pushSellhandler}
            icon={<DollarOutlined />}
            loading={loadingPushSell}
          >
            Провести продажу
          </Button>
          <Box width={30} />
          <Button type="primary" disabled={isCredit || getSelData.state === '1'} onClick={pushPreSellhandler} icon={<DollarOutlined />} loading={loadingPushPreSell}>Провести ПЕРДпродажу</Button>
          <Box width={30} />
          <DatePicker style={{ width: '300px' }} disabled={isCredit || getSelData.state === '1'} value={paymentDate || moment()} onChange={(e: any) => setPaymentDate(e)} />
        </Box>
)}
      style={{ width: '90vw' }}
      width="90vw"
      zIndex={900}
      onCancel={closeHandler}
      bodyStyle={{ width: '90vw', overflowY: 'scroll', top: 10 }}
      onOk={getSelData.state === '1' ? closeHandler : createSell}
    >
      {isUnSaved
        && (
        <>
          <Alert message="В списе товаров есть не сохраненные элементы, в случае проведения продажи они не будут учтены!" type="warning" showIcon />
          <Divider />
        </>
        )}
      {isCredit
        && (
        <>
          <Alert message="Не все товары оплачены, закрыть сделку - невозможно!" type="error" showIcon />
          <Divider />
        </>
        )}
      <Box>
        {
            isGift ? (
              <Box direction="row" justify="space-between">
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                  <label style={{ marginBottom: '7px' }}>
                    Дата
                  </label>
                  <DatePicker
                    disabled={getSelData.state === '1'}
                    style={{ width: '300px' }}
                    value={getSelData.date_created || moment()}
                    onChange={(e: any) => handler('date_created', e)}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                  <label style={{ marginBottom: '7px' }}>
                    На кого списывать
                  </label>
                  <Select
                    style={{ width: '300px' }}
                    disabled={getSelData.state === '1'}
                    onChange={(e: any) => handler('gift_by', e)}
                    value={getSelData.gift_by}
                    placeholder="Выберите на кого списывать в личное пользование"
                    options={(balances?.records || []).filter((el: any) => el.type === 'personal').map((el: StateData) => ({ label: el.name, value: el.main_id }))}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                  <label style={{ marginBottom: '7px' }}>
                    Продавец
                  </label>
                  <Select
                    style={{ width: '300px' }}
                    onChange={(e: any) => handler('seller', e)}
                    value={getSelData.seller}
                    disabled={getSelData.state === '1'}
                    placeholder="Выберите кто продал"
                    options={(balances?.records || []).filter((el: any) => el.type === 'personal').map((el: StateData) => ({ label: el.name, value: el.main_id }))}
                  />
                </div>
              </Box>
            ) : (
              <>
                <Box direction="row" justify="space-between">
                  <BorderedBox style={{ width: '30%' }}>
                    <>
                      <Box>
                        <Typography.Text type="secondary">
                          Дата
                        </Typography.Text>
                        <DatePicker
                          disabled={getSelData.state === '1'}
                        // style={{ width: '300px' }}
                          value={moment(getSelData.date_created) || moment()}
                          onChange={(e: any) => handler('date_created', e)}
                        />
                      </Box>
                      <Box margin={[1, 0, 0, 0]}>
                        <Typography.Text type="secondary">
                          Статус заказа
                        </Typography.Text>
                        <Select
                          onChange={(e: any) => handler('state', e)}
                          value={getSelData.state}
                          disabled={getSelData.state === '1'}
                          placeholder="Выберите статус"
                          options={sorter(dictionaries?.sale_type || []).map((el: StateData) => ({ label: el.name, value: el.id }))}
                        />
                      </Box>
                      <Box margin={[1, 0, 0, 0]}>
                        <Typography.Text type="secondary">
                          Наименование покупателя
                        </Typography.Text>
                        <Input
                          value={getSelData.place}
                          onChange={(e: any) => handler('place', e.target.value)}
                          disabled={isGift || getSelData.state === '1'}
                          placeholder="Желательно к заполнению"
                        />
                      </Box>
                      <Box margin={[1, 0, 0, 0]}>
                        <Typography.Text type="secondary">
                          Идентификатор покупателя (тел)
                        </Typography.Text>
                        <Input
                          value={getSelData.client_identy}
                          onChange={(e: any) => handler('client_identy', e.target.value)}
                          disabled={isGift || getSelData.state === '1'}
                          placeholder="Желательно к заполнению"
                        />
                      </Box>
                    </>
                  </BorderedBox>

                  <BorderedBox style={{ width: '30%' }}>
                    <>
                      <Box>
                        <Typography.Text type="secondary">
                          Продавец
                        </Typography.Text>
                        <Select
                      // style={{ width: '300px' }}
                          onChange={(e: any) => handler('seller', e)}
                          value={getSelData.seller}
                          disabled={getSelData.state === '1'}
                          placeholder="Выберите кто продал"
                          options={(balances?.records || []).filter((el: any) => el.type === 'personal').map((el: StateData) => ({ label: el.name, value: el.main_id }))}
                        />
                      </Box>
                      <Box margin={[1, 0, 0, 0]}>
                        <Typography.Text type="secondary">
                          Наша доставка
                        </Typography.Text>
                        <Input
                          disabled={getSelData.state === '1'}
                          value={getSelData.delivery_our}
                          onChange={(e: any) => handler('delivery_our', e.target.value)}
                          placeholder="Желательно к заполнению"
                        />

                      </Box>
                      <Box margin={[1, 0, 0, 0]}>
                        <Typography.Text type="secondary">
                          Кто оплатил доставку
                        </Typography.Text>
                        <Select
                      // style={{ width: '300px' }}
                          onChange={(e: any) => handler('delivery_payer', e)}
                          value={getSelData.delivery_payer}
                          disabled={getSelData.state === '1' || !(Number(getSelData.delivery_our) > 0)}
                          placeholder="Выберите кто оплатил"
                          options={(balances?.records || []).filter((el: any) => el.type === 'personal').map((el: StateData) => ({ label: el.name, value: el.main_id }))}
                        />
                      </Box>
                      <Box margin={[1, 0, 0, 0]}>
                        <Typography.Text type="secondary">
                          Налог (%)
                        </Typography.Text>
                        <Input
                          disabled={getSelData.state === '1'}
                          value={getSelData.tax}
                          onChange={(e: any) => handler('tax', e.target.value)}
                          placeholder="Желательно к заполнению"
                        />
                      </Box>
                    </>
                  </BorderedBox>

                  <BorderedBox style={{ width: '30%' }}>
                    <>
                      <Box direction="row" align="center">
                        <Box width="50%">
                          <Typography.Text type="secondary">
                            Тип оплаты
                          </Typography.Text>
                          <Select
                            onChange={(e: any) => handler('payment_services', e)}
                            value={getSelData.payment_services}
                            disabled={getSelData.state === '1'}
                            placeholder="Выберите тип"
                            options={dictionaries?.payment_services?.map((el: StateData) => ({ label: el.name, value: el.id }))}
                          />
                        </Box>
                        <Box>
                          <Typography.Text type="secondary" style={{ color: '#fff' }}>
                            Ghb
                          </Typography.Text>
                          <Box direction="row">
                            {
                              !addInvoice && invoice !== '0' ? (
                                <Button
                                  icon={<CloseCircleOutlined style={{ color: 'red' }} />}
                                  style={{ background: 'transparent', borderColor: '#fff', color: 'red' }}
                                  onClick={invoiceHandleCancel}
                                >
                                  Сбросить
                                </Button>
                              ) : (
                                <Button
                                  icon={!addInvoice ? <ExportOutlined /> : <CloseCircleOutlined style={{ color: 'red' }} />}
                                  style={{ background: 'transparent', borderColor: '#fff', color: addInvoice ? 'red' : '', width: '50%' }}
                                  onClick={() => setAddInvoice(!addInvoice)}
                                  disabled={getSelData.payment_services !== '1'}
                                >
                                  {addInvoice ? 'Отмена' : 'Привязать чек'}
                                </Button>
                              )
                            }

                            {addInvoice ? (
                              <Button
                                icon={<CheckCircleOutlined />}
                                style={{ background: 'transparent', color: 'green', width: '40%', borderColor: '#fff' }}
                                onClick={invoiceHandle}
                                disabled={getSelData.payment_services !== '1'}
                              >
                                Выбрать
                              </Button>
                            ) : null}
                          </Box>
                        </Box>
                      </Box>

                      {!addInvoice ? (
                        <>
                          <Box margin={[1, 0, 0, 0]} direction="row">
                            <Box>
                              <Typography.Text type="secondary">
                                Номер счета
                              </Typography.Text>
                              <Input
                                disabled={getSelData.state === '1'}
                                value={getSelData.invoice}
                                onChange={(e: any) => handler('invoice', e.target.value)}
                                placeholder="Номер счета"
                              />
                            </Box>
                            <Box>
                              <Typography.Text type="secondary">
                                Комиссия
                              </Typography.Text>
                              <Input
                                disabled={getSelData.state === '1'}
                                value={getSelData.payment_tax}
                                onChange={(e: any) => handler('payment_tax', e.target.value)}
                                placeholder="Доп комиссия"
                              />
                            </Box>
                          </Box>
                          <Box margin={[1, 0, 0, 0]}>
                            <Typography.Text type="secondary">
                              Идентификатор платежа
                            </Typography.Text>
                            <Input
                              disabled={getSelData.state === '1'}
                              value={getSelData.payment_id}
                              onChange={(e: any) => handler('payment_id', e.target.value)}
                              placeholder="Номер счета"
                            />
                          </Box>
                          <Box margin={[1, 0, 0, 0]}>
                            <Typography.Text type="secondary">
                              RRN
                            </Typography.Text>
                            <Input
                              disabled={getSelData.state === '1'}
                              value={getSelData.RRN}
                              onChange={(e: any) => handler('RRN', e.target.value)}
                              placeholder="RRN"
                            />
                          </Box>
                        </>
                      ) : (
                        <Box>
                          <Box>
                            <Typography.Text type="secondary">
                              Выберите чек
                            </Typography.Text>
                            <Select
                              onChange={(e: any) => setInvoice(e)}
                              value={invoice}
                              disabled={getSelData.state === '1'}
                              placeholder="Выберите чек"
                              options={invoicesOptions}
                            />
                          </Box>
                          {
                            invoice !== '0' ? (
                              <Box>
                                <Typography.Text type="secondary">
                                  Содержимое чека
                                </Typography.Text>
                                <DataList
                                  list={recipts?.items[0].items.map((el: any) => ({
                                    title: el.description,
                                    value: el.quantity,
                                  })) || []}
                                />
                              </Box>
                            ) : null
                          }

                        </Box>
                      )}
                    </>
                  </BorderedBox>

                </Box>

              </>
            )
        }

        {/*  <Box direction="row" width="100%">
          <Form.Item name="comment" label="Комментарий">
            <Input.TextArea style={{ width: '770px' }} />
          </Form.Item>
        </Box> */}
      </Box>
      <Divider />
      <Box>
        <Table
          columns={COLUMN}
          dataSource={sellList}
          style={{ width: '100%' }}
          size="small"
          loading={isLoadingLow}
          pagination={false}
        />
      </Box>
    </Modal>
  );
};

export default observer(SellModal);
