import React, { useState } from 'react';

import {
  Button, Checkbox, Divider, Popover, Typography,
} from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { Box } from 'components/Box';

type Props = {
    title?: string;
    data: any;
    onChange: any;
    defaultValue: string[];
}

const Filter = ({ title, data, onChange, defaultValue }: Props) => {
  const [selectedH, setSelectedH] = useState<string[]>(defaultValue);
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const setValues = () => {
    onChange(selectedH);
    handleVisibleChange(!visible);
  };

  const dropDefault = () => {
    onChange(defaultValue);
    handleVisibleChange(!visible);
  };

  return (
    <Popover
      visible={visible}
      trigger="click"
      content={(
        <Box>
          <Checkbox.Group options={data} defaultValue={data} value={selectedH} onChange={(e: any) => setSelectedH(e)} />
          <Divider />
          <Box direction="row" justify="flex-end">
            <Button onClick={dropDefault}>Сбросить</Button>
            <Button style={{ marginLeft: 20 }} onClick={() => handleVisibleChange(!visible)}>Закрыть</Button>
            <Button style={{ marginLeft: 20 }} type="primary" onClick={setValues}>Применить</Button>
          </Box>
        </Box>

)}
      onVisibleChange={handleVisibleChange}
    >
      <Box direction="row" align="center" style={{ cursor: 'pointer' }} onClick={() => setVisible(!visible)}>
        <Typography.Text type="secondary">
          {title || 'Фильтр'}
        </Typography.Text>
        <FilterOutlined style={{ color: '#BDBDBD', marginLeft: 10 }} />

      </Box>
    </Popover>

  );
};

export default Filter;
