import { useEffect, useState } from 'react';

import {
  Button, Form, Input, Modal, Select, Image, Collapse, Switch,
} from 'antd';
import {
  addProduct, editProduct, getDict,
} from 'configs';
import { PlusOutlined } from '@ant-design/icons';
import useRequest from 'hooks/useRequest';
import store from 'store/mainStore';
import { observer } from 'mobx-react';
import Upload, { RcFile, UploadFile, UploadProps } from 'antd/lib/upload';

const { Panel } = Collapse;

type Props = {
    toogle: any;
    isOpen: boolean;
    product?: any;
}

const ProductModal = ({ isOpen, toogle, product }: Props) => {
  const { userData, dictionaries, setDictionary } = store;
  const { fetch, isSuccess, isLoading, dropState } = useRequest(addProduct);
  const { fetch: fetchDict, requestResult: dict, isSuccess: suss } = useRequest(getDict);
  const { fetch: fetchEdit, isSuccess: isSuccessEdit, isLoading: isLoadingEdit, dropState: ds } = useRequest(editProduct);

  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [createSeller, setCreateSeller] = useState(true);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleCancel = () => setPreviewOpen(false);

  const getBase64 = (file: RcFile): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }: any) => setFileList(newFileList);
  const pictures = product?.pic ? JSON.parse(product?.pic) : [];

  const onFinish = () => {
    const value = form.getFieldsValue();
    const filtredImg = fileList.map((el: any) => el.thumbUrl);
    if (product?.product_id) {
      fetchEdit({
        ...product,
        ...value,
        pic: [...filtredImg, ...pictures],
        // add_by: value.add_by,
      });
    } else {
      fetch({
        name: value.name,
        link_href: value.link_href || '',
        category_id: value.category_id || 1,
        state: value.state || 1,
        link_vk: value.link_vk || '',
        price_vk: value.price_vk || 0,
        link_avito: value.link_avito || '',
        price_avito: value.price_avito || 0,
        market_price_min: value.market_price_min || 0,
        market_price_max: value.market_price_max || 0,
        rec_price: value.rec_price || 0,
        rec_price_sale: value.rec_price_sale || 0,
        produce_days: value.produce_days || 0,
        price: value.price || 0,
        comment: value.comment || '',
        weight: value.weight || 0,
        min_pack: value.min_pack || 1,
        add_by: `${userData.id}`,
        seller_id: createSeller ? 0 : value.seller_id,
        pic: filtredImg,
        ozone_id: value.ozone_id,
        curr: value.curr,
      });
    }
  };

  useEffect(() => {
    fetchDict();
    toogle(false);
    dropState();
    ds();
    form.resetFields();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isSuccessEdit]);

  const options = dictionaries?.product_status?.map((el: any) => ({ label: el.name, value: el.id })) || [];
  const optionsSellers = dict?.sellers?.map((el: any) => ({ label: el.name, value: el.id })) || [];

  const currOptions = [
    { label: 'RUB', value: 'RUB' },
    { label: 'CNY', value: 'CNY' },
  ];

  useEffect(() => {
    if (suss && dict) {
      setDictionary(dict);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suss]);

  useEffect(() => {
    if (!isOpen) form.setFieldsValue({ });
    if (isOpen && product) {
      form.setFieldsValue({ ...product });
    } else {
      form.resetFields();
      setFileList([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Загрузить</div>
    </div>
  );

  return (
    <Modal
      visible={isOpen}
      title="Новый товар"
      footer={false}
      style={{ width: '90vw', height: '90vh', top: 10 }}
      width="90vw"
      zIndex={100}
      onCancel={() => toogle(false)}
      bodyStyle={{ width: '90vw', minHeight: '90vh' }}
    >
      <Form
        name="product_form"
        // onFinish={onFinish}
        layout="inline"
        form={form}
        initialValues={{ add_by: `${userData.id}`, state: '1', curr: 'CNY' }}
      >
        <Collapse defaultActiveKey={[1]} style={{ width: '100%' }}>
          <Panel header="Основная информация" style={{ width: '100%' }} key={1}>
            <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
              <Form.Item name="name" label="Название" rules={[{ required: true }]}>
                <Input style={{ width: '400px' }} />
              </Form.Item>
              <Form.Item name="link_href" label="Ссылка на источник">
                <Input style={{ width: '400px' }} />
                {/* <Button onClick={stillHandler}>Стянуть товар</Button> */}
              </Form.Item>
              <Form.Item name="curr" label="Валюта покупки">
                <Select
                  style={{ width: '100px' }}
                  options={currOptions}
                />
              </Form.Item>
              <Form.Item name="category_id" label="Категория">
                <Select
                  style={{ width: '400px' }}
                  options={(dictionaries?.prod_category || []).map((el: any) => ({ label: el.name, value: el.id }))}
                />
              </Form.Item>
              <Form.Item name="state" label="Статус">
                <Select
                  style={{ width: '400px' }}
                  options={options}
                />
              </Form.Item>
            </div>
          </Panel>

          <Panel header="Медиа информация" key={2}>
            <div style={{ width: '100%' }}>
              {pictures.length
                ? (
                  <Image.PreviewGroup>
                    {
               pictures.map((el: any) => (
                 <Image width={200} src={el} />
               ))
            }
                  </Image.PreviewGroup>
                )
                : null}
              <Form.Item label="Изборажения">
                <Upload
              // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture-card"
                  fileList={fileList}
                  multiple
                  onPreview={handlePreview}
                  onChange={handleChange}
                >
                  {fileList.length >= 8 ? null : uploadButton}
                </Upload>
              </Form.Item>
              <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
              </Modal>
              <Form.Item name="comment" label="Комментарий">
                <Input.TextArea style={{ width: '800px', minHeight: '500px' }} />
              </Form.Item>
            </div>
          </Panel>
          <Panel header="Маркетплейсы" key={3}>
            <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>

              <Form.Item name="ozone_id" label="Ozone ID">
                <Input style={{ width: '200px' }} />
              </Form.Item>
            </div>
          </Panel>
          <Panel header="Ссылки и цены" key={4}>
            <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>

              <Form.Item name="link_vk" label={<a href={product?.link_vk || '#'} target="_blank" rel="noreferrer">Ссылка ВК</a>}>
                <Input style={{ width: '200px' }} />
              </Form.Item>
              <Form.Item name="price_vk" label="Цена ВК">
                <Input type="number" style={{ width: '200px' }} />
              </Form.Item>
              <Form.Item name="link_avito" label={<a href={product?.link_avito || '#'} target="_blank" rel="noreferrer">Ссылка Авито</a>}>
                <Input style={{ width: '200px' }} />
              </Form.Item>
              <Form.Item name="price_avito" label="Цена Авито">
                <Input type="number" style={{ width: '200px' }} />
              </Form.Item>
              <Form.Item name="market_price_min" label="Цена на рынке низ">
                <Input type="number" style={{ width: '200px' }} />
              </Form.Item>
              <Form.Item name="market_price_max" label="Цена на рынке верх">
                <Input type="number" style={{ width: '200px' }} />
              </Form.Item>
              <Form.Item name="rec_price" label="Цена розница">
                <Input type="number" style={{ width: '200px' }} />
              </Form.Item>
              <Form.Item name="rec_price_sale" label="Цена опт/спец цена">
                <Input type="number" style={{ width: '200px' }} />
              </Form.Item>
            </div>
          </Panel>
          <Panel header="Закупочная информация" key={5}>
            <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'flex-start' }}>

              <Form.Item name="produce_days" label="Длительность производства">
                <Input style={{ width: '400px' }} />
              </Form.Item>
              <Form.Item name="price" label="Стоимость">
                <Input style={{ width: '400px' }} />
              </Form.Item>
              <Form.Item name="weight" label="Вес единицы в граммах">
                <Input style={{ width: '400px' }} />
              </Form.Item>
              <Form.Item name="min_pack" label="Минимально к заказу">
                <Input style={{ width: '400px' }} />
              </Form.Item>
              <Form.Item name="seller_id" label="Продавец">
                <Select
                  style={{ width: '400px' }}
                  options={optionsSellers}
                  disabled={createSeller && !product?.product_id}
                />
              </Form.Item>
              {
                !product?.product_id
                  ? (
                    <Form.Item name="seller" label="Продавец">
                      <Switch onChange={() => setCreateSeller(!createSeller)} checked={createSeller} checkedChildren="Создать продавца" unCheckedChildren="Выбрать продавца" />
                    </Form.Item>
                  ) : null
              }

            </div>
          </Panel>
        </Collapse>

        <Form.Item>
          <div className="mt20" />
          <Button type="primary" onClick={onFinish} style={{ width: '200px' }} loading={isLoading || isLoadingEdit}>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(ProductModal);
