/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import {
  Button, Col, Divider, Input, Modal, Row, Select, Typography,
} from 'antd';
import {
  crateOrder, editOrder, getBalanceFlow, getOrdersList,
} from 'configs';
import { CopyTwoTone } from '@ant-design/icons';
import useRequest from 'hooks/useRequest';
import store from 'store/mainStore';
import { observer } from 'mobx-react';
import TransferModal from 'components/modals/TransferModal';
import { Box } from 'components/Box';
import BorderedBox from 'components/BorderedBox/BorderedBox';
import DataList, { DataListUnit } from 'components/BorderedBox/DataList';
import TableBox from 'components/TableBox/TableBox';
import { createSumm } from 'pages/Finance/Flow';

import ProductInOrder from './ProductInOrder';

import './style.scss';

const COLUMN = [
  {
    title: 'id',
    dataIndex: 'id',
    width: 70,
  },
  {
    title: 'От',
    dataIndex: 'from_name',
    width: 100,
    render: (row: any, rows: any) => (rows.from_id === '0' ? 'Пополнение' : row),
  },
  {
    title: 'Кому',
    dataIndex: 'to_name',
    maxWidth: 100,
    ellipsis: true,
  },
  {
    title: 'Сумма',
    dataIndex: 'amount',
    maxWidth: 150,
    ellipsis: true,
    render: (_: any, row: any) => createSumm(row, row.rate),
  },
  {
    title: 'Курс',
    dataIndex: 'rate',
    maxWidth: 50,
    ellipsis: true,
  },
  {
    title: 'Тип',
    dataIndex: 'op_type',
    maxWidth: 50,
    ellipsis: true,
  },
  {
    title: 'Валюта',
    dataIndex: 'amount',
    width: 90,
    render: (_: any, row: any) => <Typography.Text type="secondary">{`${row.from_curr}/${row.to_curr}`}</Typography.Text>,
  },
  {
    title: 'Дата',
    dataIndex: 'date',
    width: 180,
  },
  {
    title: 'Автор платежа',
    dataIndex: 'author',
    ellipsis: true,
  },
];

type Props = {
    toogle: any;
    isOpen: boolean;
    id: number;
}

const OrderModal = ({ isOpen, toogle, id = 0 }: Props) => {
  const { fetch: createOrder, isSuccess: createOrderSuccess, dropState } = useRequest(crateOrder);
  const { fetch: fetchUdateOrder, isSuccess: orderUpdated, dropState: dropUpdate } = useRequest(editOrder);
  const { fetch: fetchOneOrder, requestResult: fetchedOrder } = useRequest(getOrdersList);

  const [transferOpen, setTransferOpen] = useState<boolean>(false);

  const [orderData, setOrderData] = useState<any>({ exchange_rate: 10, exchange_rate_usd: 63, state: '1' });

  const { userData, dictionaries } = store;
  const [list, setList] = useState<any[]>([]);
  const isEdit = id > 0;

  const getOrder = () => fetchOneOrder({}, { id });

  const onFinish = () => {
    if (isEdit) {
      const body = {
        product_list: list,
        order: orderData,
        updated_by: `${userData.id}`,
      };

      fetchUdateOrder({
        ...body,
      });
    } else {
      const body = {
        product_list: list,
        order: orderData,
        created_by: `${userData.id}`,
      };

      createOrder({
        ...body,
      });
    }
  };

  useEffect(() => {
    if (isEdit && orderUpdated) {
      getOrder();
      dropUpdate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderUpdated]);

  useEffect(() => {
    if (!createOrderSuccess) return;
    toogle(0);
    dropState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderSuccess]);

  const handler = (key: string, value: any) => {
    const d = { ...orderData };
    d[key] = value;
    setOrderData({ ...d });
  };

  const options = dictionaries?.order_status?.map((el: any) => ({
    label: el.name,
    value: el.id,
  })) || [];

  useEffect(() => {
    if (id < 1) return;
    isEdit && getOrder();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id < 1) return;
    isEdit && getOrder();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const weight = () => {
    let count = 0;
    list.map((el: any) => (el.state !== '11' ? count += el.count * Number(el.weight) : null));
    return (count || 0) / 1000;
  };

  const uan = () => {
    let count = 0;
    list.map((el: any) => (el.state !== '11' ? count += el.count * Number(el.price) : null));
    return count || 0;
  };

  const countDelivery = () => {
    let count = 0;
    list.map((el: any) => (el.state !== '11' ? count += Number(el.in_produce_transfer_coast || 0) * Number(orderData.exchange_rate) : null));
    list.map((el: any) => (el.state !== '11' ? count += Number(el.to_seller_country_coast || 0) * Number(orderData.exchange_rate_usd) * (el.weight / 1000) * el.count : null));
    list.map((el: any) => (el.state !== '11' ? count += Number(el.in_seller_country_coast || 0) : null));
    list.map((el: any) => (el.state !== '11' ? count += Number(el.ex_delivery_coast || 0) : null));
    list.map((el: any) => (el.state !== '11' ? count += Number(el.marker_coast || 0) : null));
    return count;
  };

  const transfertoogle = (state: boolean) => {
    if (!state) {
      getOrder();
    }
    setTransferOpen(state);
  };

  useEffect(() => {
    if (fetchedOrder) {
      setOrderData(fetchedOrder?.records[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedOrder]);

  const strah = (uan() * orderData.exchange_rate * (orderData.insurance / 100)) || 0;
  const delivery = countDelivery();
  const packCoast = ((orderData.pack_coast || 0) * orderData.exchange_rate_usd);
  const ppricerub = uan() * orderData.exchange_rate;

  const aboutDeal: DataListUnit[] = [
    {
      title: 'Позиций товара',
      value: `${list.filter((el: any) => el.state !== '11').length} шт.`,
    },
    {
      title: 'Общий вес',
      value: `${weight()} kg`,
    },
    {
      title: 'Доставка',
      value: delivery.toFixed(2).toLocaleString(),
    },
    {
      title: 'Упаковка',
      value: packCoast.toFixed(2).toLocaleString(),
    },
    {
      title: 'Сумма страховки',
      value: Number(strah.toFixed(2)).toLocaleString(),
    },
    {
      title: 'Цена в валюте сделки',
      value: Number(uan()).toLocaleString(),
    },
    {
      title: 'Цена в руб',
      value: Number(ppricerub.toFixed(2)).toLocaleString(),
    },
    {
      title: 'Итого',
      value: Number((delivery + ppricerub + packCoast + strah).toFixed(2)).toLocaleString(),
    },
  ];

  return (
    <Modal
      // visible
      visible={isOpen}
      destroyOnClose
      title={(
        <Box direction="row" justify="space-between">
          <Box>{isEdit ? `Заказ: ${orderData.order_number}` : 'Новый заказ'}</Box>
          <Box direction="row" style={{ marginRight: '40px' }} align="center">
            <Box style={{ marginRight: '20px', fontSize: '12px' }}>
              Статус Заказа:
            </Box>
            <Box style={{ width: '200px' }}>
              <Select
                options={options}
                value={orderData.state}
                onChange={(state: any) => handler('state', state)}
              />
            </Box>

          </Box>
        </Box>
      )}
      footer={false}
      style={{ width: '100vw', top: 0, maxWidth: 1400 }}
      width="100vw"
      zIndex={100}
      onCancel={() => toogle(false, {})}
      bodyStyle={{ width: '100vw', maxWidth: 1400 }}
    >
      <TransferModal
        isOpen={transferOpen}
        toogle={transfertoogle}
        availableFrom={['transit', 'system', 'personal', 'empolyee']}
        availableTo={['transit', 'seller', 'delivery', 'empolyee']}
        initValues={{
          tags: `{order:${orderData.order_number},}`,
        }}
      />
      <Row>
        <Col span={18} push={6}>
          <TableBox
            title="Переводы внутри заказа"
            column={COLUMN}
            scroll={300}
            preapliedFilters={{
              tags: `DKO-${id}`,
            }}
            config={getBalanceFlow}
          />
        </Col>
        <Col span={6} pull={18}>
          <BorderedBox style={{ width: 300 }}>
            <>
              <Box>
                <Typography.Text type="secondary">
                  Название
                </Typography.Text>
                <Input
                  value={orderData.name}
                  onChange={(e: any) => handler('name', e.target.value)}
                />
              </Box>
              <Box direction="row" margin={[1, 0]}>
                <Box>
                  <Typography.Text type="secondary">
                    Курс заказа
                  </Typography.Text>
                  <Input
                    value={orderData.exchange_rate}
                    onChange={(e: any) => handler('exchange_rate', e.target.value)}
                  />
                </Box>
                <Box width={10} />
                <Box>
                  <Typography.Text type="secondary">
                    Курс USD
                  </Typography.Text>
                  <Input
                    value={orderData.exchange_rate_usd}
                    onChange={(e: any) => handler('exchange_rate_usd', e.target.value)}
                  />
                </Box>
              </Box>
              <Box margin={[1, 0]} direction="row">
                <Box>
                  <Typography.Text type="secondary">
                    Страховка %
                  </Typography.Text>
                  <Input
                    value={orderData.insurance}
                    onChange={(e: any) => handler('insurance', e.target.value)}
                  />
                </Box>
                <Box width={10} />
                <Box>
                  <Typography.Text type="secondary">
                    Упаковка
                  </Typography.Text>
                  <Input
                    value={orderData.pack_coast}
                    onChange={(e: any) => handler('pack_coast', e.target.value)}
                  />
                </Box>
              </Box>
            </>
          </BorderedBox>
          <BorderedBox top={3} style={{ width: 300 }}>
            <>
              <Box direction="row" justify="space-between" align="center">
                <Typography.Text type="secondary">
                  Сводные данные заказа
                </Typography.Text>
                <CopyTwoTone style={{ cursor: 'pointer' }} onClick={() => {}} />
              </Box>
              <Divider style={{ margin: '10px 0px' }} />
              <DataList list={aboutDeal} />
              {/* Цена за единицу */}
            </>
          </BorderedBox>
          <Box width={300} margin={[3, 0]}>
            <Button onClick={() => transfertoogle(true)} type="primary">Создать перевод по заказу</Button>
          </Box>
        </Col>
      </Row>
      <Box>
        <Divider />
        <Typography.Title level={5}>Товары в заказе</Typography.Title>
        <Divider />
      </Box>

      <ProductInOrder upData={setList} isEdit={isEdit} orderData={orderData} getOrder={getOrder} />
      <div className="mt20 divider" />
      <div className="mt20" />
      <Button type="primary" onClick={onFinish}>{isEdit ? 'Сохранить' : 'Добавить заказ'}</Button>
    </Modal>
  );
};

export default observer(OrderModal);
