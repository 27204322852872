import {
  PageHeader, Skeleton, Statistic, Tag, Typography,
} from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { getSells, getSellsTotal } from 'configs';
import { observer } from 'mobx-react';
// import store from 'store/mainStore';
import sellStorage from 'store/sellStore';
import TableBox from 'components/TableBox/TableBox';
import { Box } from 'components/Box';
// import store from 'store/mainStore';
import useRequest from 'hooks/useRequest';
import { useMount } from 'hooks';
import BorderedBox from 'components/BorderedBox/BorderedBox';

const Sells = () => {
  const { fillStore } = sellStorage;
  const { fetch, requestResult: types } = useRequest(getSellsTotal);
  // const { dictionaries } = store;

  /* const typeOptions = (dictionaries?.sale_type || []).map((el: any) => ({
    label: el.name,
    value: el.id,
    search: el.name,
  })) || []; */

  useMount(() => fetch());

  const filters = [
    /*  {
      name: 'state',
      type: 'select',
      label: 'Статус',
      placeholder: 'Выберите статус',
      options: typeOptions,
    }, */
    {
      name: 'date_closed',
      type: 'date',
      label: 'Дата закрытия',
      placeholder: 'Выберите дату закрытия',
    },
  ];

  const COLUMN = [
    {
      title: 'Статус',
      dataIndex: 'state_name',
      ellipsis: true,
      width: 140,
      render: (state: any, row: any) => (
        <Tag color={row.state === '8' ? 'red' : row.state === '1' ? 'green' : 'blue'}>
          {state}
        </Tag>
      ),
    },
    {
      title: 'Номер',
      dataIndex: 'id',
      ellipsis: true,
      render: (row: any) => (<Typography.Text>{`DKS-${row}`}</Typography.Text>
      ),
    },
    {
      title: 'Создано',
      dataIndex: 'author_name',
      minWidth: 250,
      ellipsis: true,
    },
    {
      title: 'Продавец',
      dataIndex: 'seller_name',
      minWidth: 250,
      ellipsis: true,
    },
    {
      title: 'Дата продажи',
      dataIndex: 'date_created',
      minWidth: 250,
      ellipsis: true,
    },
    {
      title: 'Обновлено',
      dataIndex: 'last_update_by',
      minWidth: 250,
      ellipsis: true,
    },
    {
      title: 'Обновлено',
      dataIndex: 'last_update',
      minWidth: 250,
      ellipsis: true,
    },
    {
      title: 'Сумма',
      dataIndex: 'amount_card',
      minWidth: 250,
      ellipsis: true,
    },
    {
      title: 'Не оплачено',
      dataIndex: 'amount_credit',
      minWidth: 250,
      ellipsis: true,
      render: (row: number) => <Typography.Text strong type={row > 0 ? 'danger' : 'success'}>{row}</Typography.Text>,
    },
  ];

  const menu = types?.records.sort((a: any, b: any) => Number(a.id) - Number(b.id)).map((el: any) => ({
    label: el.state_name,
    count: el.count,
    waiting_pay_sum: el.waiting_pay_sum,
    key: el.id,
  })) || [];

  return (
    <>
      <PageHeader
        title={(
          <Box direction="row" style={{ gridGap: 10 }} align="center">
            Корзины покупателей
            <ShoppingCartOutlined />
          </Box>
)}
      />
      <Content>

        {menu.length
          ? (
            <Box direction="row" justify="space-between" margin={[0, 0, 3, 0]}>
              {menu.map((el: any) => (
                <BorderedBox>
                  <Statistic title={el.label} value={el.count} suffix={`шт / ${el.waiting_pay_sum?.toLocaleString() || 0} ₽`} />
                </BorderedBox>
              ))}
            </Box>
          )
          : <Skeleton />}
        <TableBox
          title="Список корзин"
          filters={filters}
          column={COLUMN}
          menu={menu}
          menuDefault="1"
          menuFilterName="state"
          config={getSells}
          onRow={(record: any) => ({
            onClick: () => fillStore(record), // click row
          })}
        />
      </Content>
    </>
  );
};
export default observer(Sells);
