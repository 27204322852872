import {
  PageHeader, Tag, Typography,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { getSellsFlow } from 'configs';
import { observer } from 'mobx-react';
import TableBox from 'components/TableBox/TableBox';
import store from 'store/mainStore';
import Papa from 'papaparse';
import { saveToFile } from 'utils';

export const COLUMN_SELL_FLOW: any = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: (id: any) => <Typography.Text>{id}</Typography.Text>,
  },
  {
    title: 'Заказ',
    dataIndex: 'sell_id',
    render: (sell_id: any) => <Typography.Text>{`DKO-${sell_id}`}</Typography.Text>,
  },
  {
    title: 'Статус',
    dataIndex: 'state_name',
    fixed: 'left',
    ellipsis: true,
    width: 140,
    render: (state: any, row: any) => (
      <Tag color={row.state === '8' ? 'red' : row.state === '1' ? 'green' : 'blue'}>
        {state}
      </Tag>
    ),
  },
  {
    title: 'Продавец',
    dataIndex: 'seller_name',
    minWidth: 250,
    ellipsis: true,
  },
  {
    title: 'Дата продажи',
    dataIndex: 'date_created',
    minWidth: 250,
    ellipsis: true,
  },
  {
    title: 'Товар',
    dataIndex: 'product_name',
    minWidth: 250,
    ellipsis: true,
  },
  {
    title: 'Кол-во',
    dataIndex: 'count_sell',
    minWidth: 250,
    ellipsis: true,
  },
  {
    title: 'Цена',
    dataIndex: 'unit_price',
    minWidth: 250,
    ellipsis: true,
  },
  {
    title: 'Оплачено',
    dataIndex: 'pay_sum',
    minWidth: 250,
    ellipsis: true,
  },
  {
    title: 'CC',
    dataIndex: 'pre_price',
    minWidth: 70,
    ellipsis: true,
  },
  {
    title: 'Налог',
    dataIndex: 'tax',
    minWidth: 70,
    ellipsis: true,
  },
  {
    title: 'Комиссия',
    dataIndex: 'payment_tax',
    minWidth: 70,
    ellipsis: true,
  },
  {
    title: 'Прибыль',
    dataIndex: 'profit',
    minWidth: 70,
    ellipsis: true,
  },
  {
    title: 'Не оплачено',
    dataIndex: 'credit',
    minWidth: 250,
    ellipsis: true,
    render: (row: number) => <Typography.Text strong type={row > 0 ? 'danger' : 'success'}>{row}</Typography.Text>,
  },
];

const SellFlow = () => {
  const { dictionaries } = store;

  const typeOptions = (dictionaries?.sale_type || []).map((el: any) => ({
    label: el.name,
    value: el.id,
    search: el.name,
  })) || [];

  const filters = [
    {
      name: 'state',
      type: 'select',
      label: 'Статус',
      placeholder: 'Выберите статус',
      options: typeOptions,
    },
    {
      name: 'pay_sum',
      type: 'input',
      label: 'Сумма',
      placeholder: 'Введите сумму',
    },
    {
      name: 'sell_id',
      type: 'input',
      label: 'ID заказа',
      placeholder: 'Введите ID',
    },
    {
      name: 'name',
      type: 'input',
      label: 'Наименование товара',
      placeholder: 'Введите наименование',
    },
    {
      name: 'date',
      type: 'date',
      label: 'Дата',
      placeholder: 'Выберите дату закрытия',
    },
  ];

  const downloadList = (list: any) => {
    const prepareObject = list.records.map((el: any) => ({
      Товар: el.name,
      Продавец: el.seller_name,
      Дата: el.date_created,
      'Кол-во': el.count_sell,
      Цена: el.unit_price,
      Оплачено: el.pay_sum,
      CC: el.pre_price,
      Налог: el.tax,
      Комиссия: el.payment_tax,
      Прибыль: el.profit,
      'Не оплачено': el.credit,
    }));
    const fields = ['Товар', 'Продавец', 'Дата', 'Кол-во', 'Цена', 'Оплачено', 'CC', 'Налог', 'Комиссия', 'Прибыль', 'Не оплачено'];
    const config: any = { delimiter: ';' };
    const csv2Export = Papa.unparse({ data: prepareObject, fields }, config);
    saveToFile(csv2Export, `Продажи_${new Date()}.csv`);
  };

  return (
    <>
      <PageHeader title="Продажи" />
      <Content>
        <TableBox
          title="Список продаж"
          filters={filters}
          download={{
            func: downloadList,
            limit: 10000,
            title: 'Сохранить в файл',
          }}
          column={COLUMN_SELL_FLOW}
          config={getSellsFlow}
        />
      </Content>
    </>
  );
};
export default observer(SellFlow);
