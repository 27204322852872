import { Typography } from 'antd';
import { Box } from 'components/Box';

export type DataListUnit = {
    title: string;
    value: any;
}

type Props = {
    list: DataListUnit[];
}

const DataList = ({ list }: Props) => (
  <Box>
    {
        list.map((el:DataListUnit) => (
          <Box direction="row" justify="space-between" width="100%">
            <Typography.Link>{el.title}</Typography.Link>
            <Typography.Text strong>{el.value}</Typography.Text>
          </Box>
        ))
    }
  </Box>
);

export default DataList;
