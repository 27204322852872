import { useState } from 'react';

import { Select } from 'antd';
import { getProductList } from 'configs';
import useRequest from 'hooks/useRequest';

const SearchInput = ({ handle }: any) => {
  const [value, setValue] = useState<string>();
  const { fetch, requestResult, isLoading } = useRequest(getProductList);

  const handleSearch = (newValue: string) => {
    if (newValue) {
      fetch({}, {
        name: newValue,
      });
    }
  };

  const handleChange = (newValue: string, option: any) => {
    handle(option.data);
    setValue(newValue);
  };

  return (
    <Select
      showSearch
      value={value}
      style={{ width: '100%' }}
      placeholder="Название товара"
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      loading={isLoading}
      options={(requestResult?.records || []).map((d: any) => ({
        value: d.product_id,
        label: d.name,
        data: d,
      }))}
    />
  );
};

export default SearchInput;
