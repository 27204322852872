import { getBalanceFlow, getPersonalBalances } from 'configs';
import {
  PageHeader, Tooltip, Typography,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import TableBox from 'components/TableBox/TableBox';
import { observer } from 'mobx-react';
import useRequest from 'hooks/useRequest';
import { useMount } from 'hooks';
import store from 'store/mainStore';
import { saveToFile } from 'utils';
import Papa from 'papaparse';
import moment from 'moment';

export const createSumm = (obj: any, rate: number) => {
  const isRuble = obj.from_curr === obj.to_curr && obj.to_curr === 'RUB';
  return (
    <>
      <Typography.Text strong>{`${obj.amount} ${obj.from_curr}`}</Typography.Text>
      {!isRuble && <Typography.Text type="secondary">{` ~${(Number(obj.amount) * Number(rate)).toFixed(2)} RUB`}</Typography.Text>}
    </>
  );
};

const CashFlow = () => {
  const { fetch, requestResult, isLoading } = useRequest(getPersonalBalances);
  const { dictionaries } = store;

  useMount(() => fetch());

  const balancesOptions = requestResult?.records.map((el: any) => ({
    label: el.name,
    value: el.id,
    search: el.name,
  })) || [];

  const typeOptions = (dictionaries?.payment_types || []).map((el: any) => ({
    label: el.name,
    value: el.id,
    search: el.name,
  })) || [];

  const COLUMN = [
    {
      title: 'id',
      dataIndex: 'id',
      width: 70,
    },
    {
      title: 'От',
      dataIndex: 'from_name',
      width: 100,
      render: (row: any, rows: any) => (rows.from_id === '0' ? 'Пополнение' : row),
    },
    {
      title: 'Кому',
      dataIndex: 'to_name',
      maxWidth: 100,
      ellipsis: true,
    },
    {
      title: 'Коментарий',
      dataIndex: 'comment',
      maxWidth: 290,
      render: (row: any) => <Tooltip title={row}>{row}</Tooltip>,
      ellipsis: true,
    },
    {
      title: 'Сумма',
      dataIndex: 'amount',
      maxWidth: 150,
      ellipsis: true,
      render: (_: any, row: any) => createSumm(row, row.rate),
    },
    {
      title: 'Курс',
      dataIndex: 'rate',
      maxWidth: 50,
      ellipsis: true,
    },
    {
      title: 'Тип',
      dataIndex: 'op_type',
      maxWidth: 50,
      ellipsis: true,
    },
    {
      title: 'Валюта',
      dataIndex: 'amount',
      width: 90,
      render: (_: any, row: any) => <Typography.Text type="secondary">{`${row.from_curr}/${row.to_curr}`}</Typography.Text>,
    },
    {
      title: 'Дата',
      dataIndex: 'date',
      width: 180,
    },
    {
      title: 'Автор платежа',
      dataIndex: 'author',
      ellipsis: true,
    },
  ];

  const filters = [
    {
      name: 'from_id',
      type: 'select',
      label: 'От',
      placeholder: 'Выберите от кого',
      options: balancesOptions,

    },
    {
      name: 'to_id',
      type: 'select',
      label: 'Кому',
      placeholder: 'Выберите для кого',
      options: balancesOptions,
      isLoading,
    },
    {
      name: 'type',
      type: 'select',
      label: 'Тип операции',
      placeholder: 'Выберите тип',
      options: typeOptions,
    },
    {
      name: 'amount',
      type: 'input',
      label: 'Сумма',
      placeholder: 'Введите сумму',
    },
  ];

  const downloadList = (list: any) => {
    const prepareObject = list.records.map((el: any) => ({
      ID: el.id,
      От: el.from_name,
      Кому: el.to_name,
      Комментарий: el.comment,
      Сумма: el.amount,
      Курс: el.rate,
      Тип: el.op_type,
      Валюта: `${el.from_curr}/${el.to_curr}`,
      Дата: el.date,
    }));
    const fields = ['ID', 'От', 'Кому', 'Комментарий', 'Сумма', 'Курс', 'Тип', 'Валюта', 'Дата'];
    const config: any = { delimiter: ';' };
    const csv2Export = Papa.unparse({ data: prepareObject, fields }, config);
    saveToFile(csv2Export, `Переводы_и_оплаты_${moment().format('DD-MM-YYYY HH:MM:SS')}.csv`);
  };

  return (
    <>
      <PageHeader title="Переводы между балансами" />
      <Content>
        <TableBox
          title="Список переводов"
          filters={filters}
          column={COLUMN}
          config={getBalanceFlow}
          download={{
            func: downloadList,
            limit: 1000,
            title: 'Сохранить в файл',
          }}
        />

      </Content>
    </>
  );
};

export default observer(CashFlow);
