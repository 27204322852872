import { useEffect, useState } from 'react';

import { DatePicker, PageHeader, Typography } from 'antd';
import { Box } from 'components/Box';
import { BarcodeOutlined } from '@ant-design/icons';
import BorderedBox from 'components/BorderedBox/BorderedBox';
import { observer } from 'mobx-react';
import { RequestTypes } from 'configs';
import moment from 'moment';
import DataList, { DataListUnit } from 'components/BorderedBox/DataList';
import OzoneLogo from 'assets/images/ozoneLogo.svg';

import TransactionTabs from './TransactionTabs';
import useRequestOzone from './useRequestOzone';

const { RangePicker } = DatePicker;

export interface IRequestConfigOzone {
  link: '' | ApiLinks;
  method: '' | RequestTypes;
  tag?: string;
  version?: string;
}

enum ApiLinks {
  getRate = 'v1/rating/summary',
  getTransactions = 'v3/finance/transaction/list',
  totals = 'v3/finance/transaction/totals',
  getProductsID = 'v2/product/list',
  getProductsList = 'v2/product/info/list',
  getRateBySku = 'v1/product/rating-by-sku',
}

const getTotals: IRequestConfigOzone = {
  link: ApiLinks.totals,
  method: RequestTypes.post,
};

export const getProdIds: IRequestConfigOzone = {
  link: ApiLinks.getProductsID,
  method: RequestTypes.post,
};

export const getProdList: IRequestConfigOzone = {
  link: ApiLinks.getProductsList,
  method: RequestTypes.post,
};

export const getRateBySku: IRequestConfigOzone = {
  link: ApiLinks.getRateBySku,
  method: RequestTypes.post,
};

export const getTransactions: IRequestConfigOzone = {
  link: ApiLinks.getTransactions,
  method: RequestTypes.post,
};

const OzonPage = () => {
  const [data, setData] = useState<any>(null);
  const [maxSale, setMaxSale] = useState<any>({
    maxCount: {
      count: 0,
      summ: 0,
      name: '---',
    },
  });
  const { fetch, requestResult: amount } = useRequestOzone(getTotals);
  // const { fetch: fetchTransactions, isLoading: transactionsLiading, requestResult: transactions } = useRequestOzone(getTransactions);
  const [dates, setDates] = useState<string[]>([moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]);

  const getMaxSale = () => {
    if (!data) {
      return {
        maxCount: {
          count: 0,
          summ: 0,
          name: '---',
        },
        maxProfit: {
          count: 0,
          summ: 0,
          name: '---',
        },
      };
    }

    const { result } = data;
    const { operations } = result;
    let middleList: any[] = [];
    const maxCount = {
      count: 0,
      summ: 0,
      name: '---',
    };

    const m2list: string[] = [];

    for (let i = 0; i < operations.length; i++) {
      if (operations[i].operation_type === 'OperationAgentDeliveredToCustomer') {
        middleList = [...middleList, ...operations[i].items];
      }
    }

    for (let i = 0; i < middleList.length; i++) {
      if (!m2list.includes(middleList[i].name)) {
        m2list.push(middleList[i].name);
      }
    }

    for (let i = 0; i < m2list.length; i++) {
      const l = middleList.filter((el: any) => el.name === m2list[i]).length;
      if (l > maxCount.count) {
        maxCount.name = m2list[i];
        maxCount.count = l;
      }
    }
    setMaxSale(maxCount);
  };

  useEffect(() => {
    if (!data) return;
    fetch({
      date: {
        from: `${dates[0]}T00:00:00.000Z`,
        to: `${dates[1]}T23:59:59.000Z`,
      },
      posting_number: '',
      transaction_type: 'all',
    }, {});
    getMaxSale();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // useMount(() => fetch());

  // OperationAgentDeliveredToCustomer
  // MarketplaceRedistributionOfAcquiringOperation

  const amounts = amount?.result || {};

  const toLocal = (value: number) => (value ? `${value.toLocaleString()} rub` : '0 rub');

  const aboutDeal: DataListUnit[] = [
    {
      title: 'Общая стоимость товаров и возвратов',
      value: toLocal(amounts.accruals_for_sale),
    },
    {
      title: 'Сумма комиссии',
      value: toLocal(amounts.sale_commission),
    },
    {
      title: 'Прочие начисления',
      value: toLocal(amounts.others_amount),
    },
    {
      title: 'Стоимость услуг обработки отправлений',
      value: toLocal(amounts.processing_and_delivery),
    },
    {
      title: 'Стоимость обратной магистрали, обработки возвратов,',
      value: toLocal(amounts.refunds_and_cancellations),
    },
    {
      title: 'Стоимость дополнительных услуг',
      value: toLocal(amounts.services_amount),
    },
    {
      title: 'Чистая прибыль',
      value: toLocal(amounts.accruals_for_sale + amounts.sale_commission + amounts.others_amount + amounts.processing_and_delivery + amounts.refunds_and_cancellations + amounts.services_amount),
    },
  ];

  const popularItem: DataListUnit[] = [
    {
      title: 'Самый продаваемый 🔥',
      value: maxSale.name,
    },
    {
      title: 'Количество',
      value: `${maxSale.count} шт.`,
    },
  ];
  return (
    <>
      <PageHeader
        title={(
          <Box direction="row" justify="space-between" width="100%">
            <Box direction="row" style={{ gridGap: 10 }} align="center">
              Продажи OZONE
              <BarcodeOutlined />
            </Box>

            <RangePicker
              style={{ width: '300px', height: '32px', background: 'transparent', marginLeft: '20px', borderRadius: '10px' }}
              defaultValue={[moment().startOf('month'), moment()]}
              onChange={(v: any) => setDates([moment(v[0]).format('YYYY-MM-DD'), moment(v[1]).format('YYYY-MM-DD')])}
            />
            <Typography.Text type="danger" style={{ fontSize: '12px', marginLeft: '20px' }}>Период не больше месяца</Typography.Text>
          </Box>

)}
      />
      <Box direction="row" justify="space-between" margin={[0, 0, 5, 0]}>
        <BorderedBox style={{ width: '10%' }}>
          <img src={OzoneLogo} alt="ozoneLogo" />
        </BorderedBox>
        <BorderedBox style={{ width: '40%' }}>

          <DataList list={aboutDeal} />

        </BorderedBox>
        <BorderedBox style={{ width: '40%' }}>
          <DataList list={popularItem} />
        </BorderedBox>

      </Box>
      <TransactionTabs title="Список транзакций" column={[]} dates={dates} backFunc={setData} />
    </>
  );
};

export default observer(OzonPage);
