import { useState } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { getPersonalBalances, getPrepaid } from 'configs';
import useRequest from 'hooks/useRequest';
import { useMount } from 'hooks';
import {
  PageHeader, Card, Col, Row, Statistic, Skeleton, Button, Typography,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import TransferModal from 'components/modals/TransferModal';
import { Box } from 'components/Box';

import CreatePayementInvestModal from './CreatePayementInvestModal';

const Balances = () => {
  const { fetch, requestResult, isLoading } = useRequest(getPersonalBalances);
  const { fetch: prepaid, requestResult: prepaidsList } = useRequest(getPrepaid);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenInvest, setIsOpenInvest] = useState<boolean>(false);

  const toogleModal = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      fetch();
    }
  };

  const toogleModalInvest = (state: boolean) => {
    setIsOpenInvest(state);
    if (!state) {
      fetch();
    }
  };

  useMount(() => {
    fetch();
    prepaid();
  });

  const getPrepad = (main_id: number) => prepaidsList?.records.find((l: any) => l.seller === main_id)?.amount || 0;

  return (
    <>
      <PageHeader
        title="Балансы"
        extra={(
          <>
            <Button type="primary" onClick={() => toogleModalInvest(true)} icon={<PlusCircleOutlined />}>Инвестиция</Button>
            <Button type="primary" onClick={() => toogleModal(true)} icon={<PlusCircleOutlined />}>Создать перевод</Button>

          </>
      )}
      />
      <TransferModal
        isOpen={isOpen}
        toogle={toogleModal}
        availableFrom={['system', 'personal', 'transit', 'payment_providers', 'marketplace', 'empolyee']}
        availableTo={['system', 'personal', 'empolyee', 'transit', 'expenses', 'work_balance', 'delivery']}
      />
      <CreatePayementInvestModal isOpen={isOpenInvest} toogle={toogleModalInvest} balances={requestResult} />
      <Content>
        {
                isLoading ? <Skeleton active />
                  : (
                    <Row gutter={16}>
                      {requestResult?.records.filter((el: any) => el.type === 'personal').map((el: any) => (
                        <Col span={12}>
                          <>
                            <Card
                              title={(
                                <Box direction="row" justify="space-between">
                                  <Typography.Text>{el.name}</Typography.Text>
                                  <Typography.Text type={Number(el.amount) + getPrepad(el.main_id) > 0 ? 'success' : 'danger'}>
                                    {(Number(el.amount) + getPrepad(el.main_id)).toFixed(2)}
                                    {' '}
                                    {el.currency}
                                  </Typography.Text>
                                </Box>
)}
                            >
                              <Box direction="row" justify="space-between">
                                <Statistic title="Основной" value={`${el.amount} ${el.currency}`} />
                                <Statistic title="Предоплаты" value={`${getPrepad(el.main_id)} ${el.currency}`} />
                              </Box>
                            </Card>
                            <div className="mt20" />
                          </>
                        </Col>
                      ))}
                    </Row>
                  )
}
        <Card title="Посредники">
          {
                isLoading ? <Skeleton active />
                  : (
                    <Row gutter={16}>
                      {requestResult?.records.filter((el: any) => ['transit', 'payment_providers'].includes(el.type)).map((el: any) => (
                        <Col span={12}>
                          <Statistic title={el.name} value={`${el.amount} ${el.currency}`} />
                        </Col>
                      ))}
                    </Row>
                  )
}
        </Card>

        <Card title="Маркетплейсы">
          {
                isLoading ? <Skeleton active />
                  : (
                    <Row gutter={16}>
                      {requestResult?.records.filter((el: any) => ['marketplace'].includes(el.type)).map((el: any) => (
                        <Col span={12}>
                          <Statistic title={el.name} value={`${el.amount} ${el.currency}`} />
                        </Col>
                      ))}
                    </Row>
                  )
}
        </Card>

      </Content>
    </>
  );
};

export default observer(Balances);
