import { Typography } from 'antd';
import store, { StateData, StateDataModificators } from 'store/mainStore';

import { Box } from './Box';

type Props = {
    product_name?: string;
    data: any;
    short?: boolean;
}

const ProductBox = ({ product_name, data, short = false }: Props) => {
  const { dictionaries: { modificators } } = store;

  const getModificator = (element: any, type_name: string): string => {
    const id = Number(element);
    if (!Number(id)) return '';
    const current = modificators?.list.find((el: StateData) => el.id === id);

    const name = current?.name;

    if (short) return name || '';

    return `${type_name}: ${name}`;
  };

  const list: string[] = modificators?.types
    .map((el: StateDataModificators) => getModificator(data[el.char_code], el.name))
    || [];

  const cleanList = list.filter((el: string) => el.length > 0);

  return (
    <Box>
      {product_name && <Typography.Link>{product_name}</Typography.Link>}
      {cleanList.map((el: string) => (
        <Typography.Text type="secondary">{el}</Typography.Text>
      ))}
    </Box>
  );
};

export default ProductBox;
