import { useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import store from 'store/mainStore';
import useRequest from 'hooks/useRequest';
import LocalStorage from 'utils/LocalStorage';
import { Button, Form, Input } from 'antd';
import { usersLogin } from 'configs';

export type ILogin = {
    email: string;
    password: string;
}
const Auth = () => {
  const { setUser } = store;
  const { fetch, isSuccess, requestResult, isLoading } = useRequest(usersLogin);

  const submit = (values: any) => {
    LocalStorage.remove('a');
    LocalStorage.remove('r');
    fetch(values);
  };

  useEffect(() => {
    if (isSuccess && requestResult) {
      setUser(requestResult);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, requestResult]);

  return (
    <div className="signinForm">
      <div className="center">
        <h1>Вход</h1>
      </div>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={submit}
        autoComplete="off"
        style={{ width: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '0px' }}
      >
        <div className="signinForm_box">
          <Form.Item name="login">
            <Input style={{ width: '400px' }} />
          </Form.Item>
          <Form.Item name="password">
            <Input.Password style={{ width: '400px' }} />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary" loading={isLoading}>Войти</Button>
          </Form.Item>
        </div>

      </Form>
    </div>
  );
};
export default observer(Auth);
