/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import {
  Button, DatePicker, Input, Select, Skeleton, Typography, Col, Row, Empty, Divider,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import LocalStorage from 'utils/LocalStorage';
import store from 'store/mainStore';
import { getOrdersProductList } from 'configs';
import useRequest from 'hooks/useRequest';
import SearchInput from 'components/SearchSelect/SearchSelect';
import TransferModal from 'components/modals/TransferModal';
import { Box } from 'components/Box';
import BorderedBox from 'components/BorderedBox/BorderedBox';
import ProductBox from 'components/ProductBox';

import StoreModal from './StoreModal';

type Props = {
    upData: any;
    orderData: any;
    isEdit: boolean;
    getOrder: any;
}

const ProductInOrder = ({ upData, orderData, isEdit, getOrder }: Props) => {
  const { fetch, isLoading, requestResult } = useRequest(getOrdersProductList);
  // const { fetch: deleteFetch, isSuccess, metaData } = useRequest(deleteFromProductList);

  const [list, setList] = useState<any[]>([]);
  const [transferOpen, setTransferOpen] = useState<boolean>(false);
  const [payProd, setpayProd] = useState<any>(null);
  const [storeOpen, setStoreOpen] = useState<boolean>(false);
  const [initStore, setInitStore] = useState<any>({});
  const [OLID, setOLID] = useState<number>(0);
  const [selectedProd, setSelectedProd] = useState<any>(null);
  const [activeProd, setActiveProd] = useState<number>(0);

  const { dictionaries } = store;

  const options = dictionaries?.product_in_order_state?.map((el: any) => ({
    label: el.name,
    value: el.id,
  })) || [];

  const optionsModType = dictionaries?.modificators?.types.map((el: any) => ({
    label: el.name,
    value: el.char_code,
    id: el.id,
  })) || [];

  const ALL = [{
    label: 'Отсутствует',
    value: 0,
  }];

  const onChangeHandler = (value: any, key: any, index: number) => {
    const newList: any = [...list];

    newList[index] = { ...list[index], [key]: value };

    setList(newList);
  };

  const transfertoogle = (state: boolean, el: any) => {
    if (!state) {
      getOrder();
    }
    setTransferOpen(state);
    setpayProd(el);
  };

  useEffect(() => {
    if (isEdit) {
      fetch({}, {
        id: orderData.id,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData, isEdit]);

  useEffect(() => {
    if (requestResult) {
      setList(requestResult?.records || []);
    }
  }, [requestResult]);

  const inputs = [
    // { name: 'Название', field: 'name', type: 'input' },
    { name: 'Дней производить', field: 'produce_days', type: 'input' },
    { name: 'Цена', field: 'price', type: 'input' },
    { name: 'Вес', field: 'weight', type: 'input' },
    { name: 'Количество', field: 'count', type: 'input' },
    /* { name: 'Цена на рынке низ(руб)', field: 'market_price_min', type: 'input' },
    { name: 'Цена на рынке верх(руб)', field: 'market_price_max', type: 'input' },
    { name: 'Рекомендованя цена(руб)', field: 'rec_price', type: 'input' },
    { name: 'Цена для спец покупателей(руб)', field: 'rec_price_sale', type: 'input' }, */
  ];

  const importInputs = [
    { name: 'Начало производства', field: 'date_produce_begin', type: 'date' },
    { name: 'Окончание производства', field: 'date_produce_end', type: 'date' },
    { name: 'Отправлено на доставку', field: 'date_send_to_china_delivery', type: 'date' },
    { name: 'Доставка по Китаю(юань)', field: 'in_produce_transfer_coast', type: 'input', rate: 'exchange_rate' },
    { name: 'Цена доставки в РФ(юсд/кг)', field: 'to_seller_country_coast', type: 'input', rate: 'exchange_rate_usd', delim: true },
  ];

  const deliveryInputs = [
    { name: 'Отправлено от поставщика', field: 'date_send_to_seller_country', type: 'date' },
    { name: 'Расчетная дата прибытия', field: 'date_when_we_wait', type: 'date' },
    { name: 'Получено в нашей стране', field: 'date_get_in_seller_country', type: 'date' },
    { name: 'Цена доставки по РФ(руб)', field: 'in_seller_country_coast', type: 'input' }, // TODO ДЕЛить на все товары рподавца
  ];

  const subDelivery = [
    { name: 'Цена доп. доставки(руб)', field: 'ex_delivery_coast', type: 'input' },
    { name: 'Примечание к доп доставке', field: 'ex_delivery_comment', type: 'input' },
    { name: 'Услуги рынка(руб)', field: 'marker_coast', type: 'input' },
    { name: 'Прим. к услугам рынка', field: 'marker_comment', type: 'input' },
  ];

  const countDelivery = (lst: any) => {
    let count = 0;
    lst.state !== '11' ? count += Number(lst.in_produce_transfer_coast || 0) * Number(orderData.exchange_rate) : null;
    lst.state !== '11' ? count += Number(lst.to_seller_country_coast || 0) * Number(orderData.exchange_rate_usd) * (lst.weight / 1000) * lst.count : null;
    lst.state !== '11' ? count += Number(lst.in_seller_country_coast || 0) : null;
    lst.state !== '11' ? count += Number(lst.ex_delivery_coast || 0) : null;
    lst.state !== '11' ? count += Number(lst.marker_coast || 0) : null;
    return count || 0;
  };

  /* const del = (ind: number, el: any) => {
    if (el.id) {
      deleteFetch({ id: el.id });
    } else {
      setList(list.filter((_: any, index: number) => index !== ind));
    }
  }; */

  /* useEffect(() => {
    setList(list.filter((_: any, index: number) => index !== metaData.params.id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]); */

  useEffect(() => {
    upData(list);
    LocalStorage.set('listOrder', list);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const StoreToogle = (order_list_id: number, iv: any) => {
    setOLID(order_list_id);
    setInitStore(iv);
    setStoreOpen(!storeOpen);
  };

  return (
    isLoading ? <Skeleton />
      : (
        <>
          <StoreModal isOpen={storeOpen} toogle={StoreToogle} order_list_id={OLID} getOrder={getOrder} initValues={initStore} />
          <TransferModal
            isOpen={transferOpen}
            toogle={transfertoogle}
            availableFrom={['transit', 'system', 'personal']}
            availableTo={['transit', 'seller', 'delivery']}
            initValues={{
              amount: payProd?.price * payProd?.count,
              rate: 1,
              to_id: payProd?.seller_balance_id,
              comment: `Оплата по заказу ${orderData.order_number}, ${payProd?.name}`,
              tags: `{order:${orderData.order_number}, pid:${payProd?.product_id || ''}}`,
            }}
          />
          <Row>
            <Col span={18} push={7}>
              <BorderedBox style={{ width: '90%' }}>
                <>
                  <Box direction="row" justify="space-between" align="center">
                    <Box>
                      <Typography.Text strong>{list[activeProd]?.name}</Typography.Text>
                      <Typography.Text type="secondary">Общие данные</Typography.Text>
                    </Box>
                    <Box direction="row">
                      <Select
                        options={options}
                        defaultValue={1}
                        value={list[activeProd]?.state}
                        onChange={(d: any) => onChangeHandler(d, 'state', activeProd)}
                      />
                      <Button type="primary" onClick={() => transfertoogle(true, list[activeProd])} style={{ marginRight: '20px' }}>
                        Оплата по товару
                      </Button>
                      <Button type="primary" onClick={() => StoreToogle(list[activeProd]?.id, { max: list[activeProd]?.count, from_id: '6' })} style={{ marginRight: '20px' }} icon={<PlusOutlined />} disabled={list[activeProd]?.store_count >= list[activeProd]?.count}>
                        Отправить на склад
                      </Button>
                    </Box>
                  </Box>
                  <Divider style={{ margin: '10px 0px' }} />
                  {
                  list.length ? (
                    <div style={{ display: 'flex', maxWidth: '80vw', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                      {
                        inputs.map((l: any) => (
                          <Box width={200}>
                            <Typography.Text type="secondary">
                              {l.name}
                            </Typography.Text>
                            <Input
                              value={list[activeProd]?.[l.field]}
                              disabled={['10', '11'].includes(list[activeProd]?.state)}
                              onChange={(e: any) => onChangeHandler(e.target.value, l.field, activeProd)}
                            />
                          </Box>
                        ))
                      }
                      <Box direction="row" justify="space-between" align="center" margin={[3, 0, 0, 0]}>
                        <Typography.Text type="secondary">Модификаторы</Typography.Text>

                      </Box>
                      <Divider style={{ margin: '10px 0px' }} />
                      <Box width="100%" style={{ maxWidth: '80vw', flexWrap: 'wrap' }} justify="space-between" direction="row">
                        {
                          optionsModType.map((mod: any) => (
                            <Box width={200} margin={[1, 0, 0, 0]}>
                              <Typography.Text type="secondary">
                                {mod.label}
                              </Typography.Text>
                              <Select
                                options={[...ALL, ...dictionaries?.modificators?.list.filter((f:any) => f.type === Number(mod.id)).map((ml: any) => ({
                                  label: ml.name,
                                  value: String(ml.id),
                                }))] || []}
                                defaultValue={list[activeProd]?.[mod.value]}
                                value={String(list[activeProd]?.[mod.value])}
                                onChange={(d: any) => onChangeHandler(d, mod.value, activeProd)}
                              />
                            </Box>

                          ))
                        }
                      </Box>
                      <Box direction="row" justify="space-between" align="center" margin={[3, 0, 0, 0]}>
                        <Typography.Text type="secondary">Импорт</Typography.Text>

                      </Box>
                      <Divider style={{ margin: '10px 0px' }} />
                      <Box width="100%" style={{ maxWidth: '80vw', flexWrap: 'wrap' }} justify="space-between" direction="row">
                        {
                        importInputs.map((l: any) => (l.type === 'input' ? (
                          <Box width={200} margin={[1, 0, 0, 0]}>
                            <Typography.Text type="secondary">
                              {l.name}
                              {/* {(l.rate && orderData[l.rate] > 0 && list[activeProd]?.[l.field] && !l.delim) ? `~${(Number(orderData[l.rate]) * Number(list[activeProd]?.[l.field])).toFixed(2)}` : ''}
                              {(l.rate && orderData[l.rate] > 0 && list[activeProd]?.[l.field] && l.delim) ? `~${(Number(orderData[l.rate]) * Number(list[activeProd]?.[l.field]) * (list[activeProd].weight / 1000)).toFixed(2)}` : ''}
                              */}
                            </Typography.Text>
                            <Input
                              value={list[activeProd]?.[l.field]}
                              disabled={['10', '11'].includes(list[activeProd]?.state)}
                              onChange={(e: any) => onChangeHandler(e.target.value, l.field, activeProd)}
                            />
                          </Box>
                        ) : (
                          <Box width={200}>
                            <Typography.Text type="secondary">
                              {l.name}
                            </Typography.Text>
                            <DatePicker disabled={['10', '11'].includes(list[activeProd]?.state)} value={list[activeProd]?.[l.field] && list[activeProd]?.[l.field] !== '0000-00-00 00:00:00' ? moment(list[activeProd]?.[l.field]) : moment()} onChange={(e: any, date: any) => onChangeHandler(date, l.field, activeProd)} />
                          </Box>
                        )
                        ))
                     }
                      </Box>
                      <Box direction="row" justify="space-between" align="center" margin={[3, 0, 0, 0]}>
                        <Typography.Text type="secondary">Доставка</Typography.Text>

                      </Box>
                      <Divider style={{ margin: '10px 0px' }} />
                      <Box width="100%" style={{ maxWidth: '80vw', flexWrap: 'wrap' }} justify="space-between" direction="row">
                        {
                          deliveryInputs.map((l: any) => (l.type === 'input' ? (
                            <Box width={200}>
                              <Typography.Text type="secondary">
                                {l.name}
                                {(l.rate && orderData[l.rate] > 0 && list[activeProd]?.[l.field] && !l.delim) ? `~${(Number(orderData[l.rate]) * Number(list[activeProd]?.[l.field])).toFixed(2)}` : ''}
                                {(l.rate && orderData[l.rate] > 0 && list[activeProd]?.[l.field] && l.delim) ? `~${(Number(orderData[l.rate]) * Number(list[activeProd]?.[l.field]) * (list[activeProd].weight / 1000)).toFixed(2)}` : ''}

                              </Typography.Text>
                              <Input
                                value={list[activeProd]?.[l.field]}
                                disabled={['10', '11'].includes(list[activeProd]?.state)}
                                onChange={(e: any) => onChangeHandler(e.target.value, l.field, activeProd)}
                              />
                            </Box>
                          ) : (
                            <Box width={200}>
                              <Typography.Text type="secondary">
                                {l.name}
                              </Typography.Text>
                              <DatePicker disabled={['10', '11'].includes(list[activeProd]?.state)} value={list[activeProd]?.[l.field] && list[activeProd]?.[l.field] !== '0000-00-00 00:00:00' ? moment(list[activeProd]?.[l.field]) : moment()} onChange={(e: any, date: any) => onChangeHandler(date, l.field, activeProd)} />
                            </Box>
                          )
                          ))
                      }
                      </Box>
                      <Box direction="row" justify="space-between" align="center" margin={[3, 0, 0, 0]}>
                        <Typography.Text type="secondary">Дополнительная доставка</Typography.Text>

                      </Box>
                      <Divider style={{ margin: '10px 0px' }} />
                      <Box width="100%" style={{ maxWidth: '80vw', flexWrap: 'wrap' }} justify="space-between" direction="row">
                        {
                          subDelivery.map((l: any) => (l.type === 'input' ? (
                            <Box width={200}>
                              <Typography.Text type="secondary">
                                {l.name}
                              </Typography.Text>
                              <Input
                                value={list[activeProd]?.[l.field]}
                                disabled={['10', '11'].includes(list[activeProd]?.state)}
                                onChange={(e: any) => onChangeHandler(e.target.value, l.field, activeProd)}
                              />
                            </Box>
                          ) : (
                            <Box width={200}>
                              <Typography.Text type="secondary">
                                {l.name}
                              </Typography.Text>
                              <DatePicker disabled={['10', '11'].includes(list[activeProd]?.state)} value={list[activeProd]?.[l.field] && list[activeProd]?.[l.field] !== '0000-00-00 00:00:00' ? moment(list[activeProd]?.[l.field]) : moment()} onChange={(e: any, date: any) => onChangeHandler(date, l.field, activeProd)} />
                            </Box>
                          )
                          ))
                      }
                      </Box>
                    </div>
                  )
                    : <Empty description="Сначала надо добавить товар" />
                }
                </>
              </BorderedBox>
            </Col>
            <Col span={6} pull={18} style={{ maxHeight: 700, overflowY: 'scroll' }}>
              {
                list.map((el: any, index: number) => (
                  <Box
                    className={`productBox productBox_${activeProd === index ? 'active' : ''}`}
                    padding={[2, 2]}
                    margin={[0, 0, 2, 0]}
                    key={`${el.id}`}
                    onClick={() => setActiveProd(index)}
                  >
                    <Box direction="row" justify="space-between">
                      <Typography.Text strong>{el.name}</Typography.Text>
                      <Typography.Text strong style={{ marginLeft: '10px' }}>
                        {el.count || 0}
                        шт
                      </Typography.Text>
                    </Box>
                    <Box>
                      <ProductBox data={el} />
                    </Box>
                    <Box direction="row" justify="space-between" width="100%">
                      <Typography.Link>Стоимость</Typography.Link>
                      <Typography.Text strong>{(el.price * el.count * orderData.exchange_rate).toFixed(2) || 0}</Typography.Text>
                    </Box>
                    <Box direction="row" justify="space-between" width="100%">
                      <Typography.Link>Доставка</Typography.Link>
                      <Typography.Text strong>{countDelivery(el).toFixed(2) || 0}</Typography.Text>
                    </Box>
                    <Box direction="row" justify="space-between" width="100%">
                      <Typography.Link>Стоимость ед.</Typography.Link>
                      <Typography.Text strong>{((el.price * orderData.exchange_rate) + countDelivery(el) / el.count).toFixed(2)}</Typography.Text>
                    </Box>
                    <Box direction="row" justify="space-between" width="100%">
                      <Typography.Link>Итого</Typography.Link>
                      <Typography.Text strong>{((el.price * el.count * orderData.exchange_rate) + countDelivery(el)).toFixed(2)}</Typography.Text>
                    </Box>
                  </Box>
                ))
              }
              <Box className="productBox productBox_grey" padding={[2, 2]}>
                <Typography.Text type="secondary">
                  Добавить товар
                </Typography.Text>
                <Box height={20} />
                <SearchInput handle={setSelectedProd} />
                <Box height={20} />
                <Button
                  type="dashed"
                  onClick={() => {
                    setList([...list, selectedProd]);
                    setSelectedProd(null);
                  }}
                  block
                  icon={<PlusOutlined />}
                  disabled={!selectedProd}
                />
              </Box>
            </Col>
          </Row>
        </>
      )
  );
};

export default ProductInOrder;
