import { useState, useEffect } from 'react';

import {
  Button,
  Table, Typography,
} from 'antd';
import { Box } from 'components/Box';
import TableFooter from 'components/TableFooter';
import moment from 'moment';
import { saveToFile } from 'utils';
import Papa from 'papaparse';
import {
  DownloadOutlined,
} from '@ant-design/icons';

import { getTransactions } from './OzonPage';
import useRequestOzone from './useRequestOzone';

type Props = {
    title: string;
    extra?: JSX.Element;
    column: any[];
    filters?: any[];
    preapliedFilters?: any;
    scroll?: number;
    dates: string[];
    backFunc: any;
}

function TransactionTabs({
  scroll = 1300, dates, backFunc, title,
}: Props) {
  const { fetch, isLoading, requestResult } = useRequestOzone(getTransactions);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  useEffect(() => {
    setPage(1);
    fetch({
      filter: {
        date: {
          from: `${dates[0]}T00:00:00.000Z`,
          to: `${dates[1]}T23:59:59.000Z`,
        },
        operation_type: [],
        posting_number: '',
        transaction_type: 'all',
      },
      page: 1,
      page_size: 1000,
    }, {});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, fetch]);

  useEffect(() => {
    if (!requestResult) return;
    backFunc(requestResult);
  }, [backFunc, requestResult]);

  const COLUMN: any = [
    {
      title: 'Дата начисления',
      dataIndex: 'operation_date',
      ellipsis: true,
      width: 100,
      render: (od: any) => <Typography.Text type="secondary">{od.split(' ')[0]}</Typography.Text>,
    },
    {
      title: 'Дата операции',
      dataIndex: 'order_date',
      ellipsis: true,
      render: (_: any, row: any) => <Typography.Text type="secondary">{row.posting.order_date ? row.posting.order_date : '---'}</Typography.Text>,
    },
    {
      title: 'Тип начисления',
      dataIndex: 'operation_type_name',
      minWidth: 250,
      render: (ot: any) => <Typography.Text strong>{ot}</Typography.Text>,
    },
    {
      title: 'Идентификатор отправления или услуги',
      dataIndex: 'posting',
      width: 180,
      render: (_: any, row: any) => <Typography.Link>{row.posting.posting_number ? row.posting.posting_number : '---'}</Typography.Link>,
    },
    {
      title: 'Детали',
      dataIndex: 'items',
      width: 350,
      render: (items: any) => (
        <Box>
          {items.length < 1 ? '---' : items.map((el: any) => <Typography.Text>{el.name}</Typography.Text>)}
        </Box>
      ),
    },
    {
      title: 'За продажу или возврат',
      dataIndex: 'accruals_for_sale',
      minWidth: 250,
      ellipsis: true,
      render: (ot: any) => (
        <Typography.Text strong>
          {`${ot.toLocaleString()} ₽`}
        </Typography.Text>
      ),
    },
    {
      title: 'Комиссия за продажу',
      dataIndex: 'sale_commission',
      minWidth: 250,
      ellipsis: true,
      render: (ot: any) => (
        <Typography.Text strong>
          {`${ot.toLocaleString()} ₽`}
        </Typography.Text>
      ),
    },
    {
      title: 'Обработка и доставка',
      dataIndex: 'accruals_for_sale',
      minWidth: 250,
      ellipsis: true,
      render: (_: any, row: any) => (
        <Typography.Text strong>
          {`${row.services.reduce(
            (accumulator: any, currentValue: any) => accumulator + Number(currentValue.price), 0,
          ).toFixed(2).toLocaleString()} ₽`}
        </Typography.Text>
      ),
    },
    {
      title: 'Итого',
      dataIndex: 'amount',
      minWidth: 250,
      ellipsis: true,
      render: (ot: any) => (
        <Typography.Link strong>
          {`${ot.toLocaleString()} ₽`}
        </Typography.Link>
      ),
    },
  ];

  const downloadList = (list: any) => {
    const prepareObject = list.map((el: any) => ({
      'Дата начисления': el.operation_date.split(' ')[0],
      'Дата операции': el.posting.order_date ? el.posting.order_date : '---',
      'Тип начисления': el.operation_type_name,
      'Идентификатор отправления или услуги': el.posting.posting_number ? el.posting.posting_number : '---',
      Детали: el.items.length < 1 ? '---' : el.items.map((ela: any) => ela.name).join(', '),
      'За продажу или возврат': `${el.accruals_for_sale.toLocaleString()} ₽`,
      'Комиссия за продажу': `${el.sale_commission.toLocaleString()} ₽`,
      'Обработка и доставка': `${el.services.reduce(
        (accumulator: any, currentValue: any) => accumulator + Number(currentValue.price), 0,
      ).toFixed(2).toLocaleString()} ₽`,
      Итого: `${el.amount.toLocaleString()} ₽`,
    }));
    // eslint-disable-next-line max-len
    const fields = ['Дата начисления', 'Дата операции', 'Тип начисления', 'Идентификатор отправления или услуги', 'Детали', 'За продажу или возврат', 'Комиссия за продажу', 'Обработка и доставка', 'Итого'];
    const config: any = { delimiter: ';' };
    const csv2Export = Papa.unparse({ data: prepareObject, fields }, config);
    saveToFile(csv2Export, `OZON_TRANS_${new Date()}.csv`);
  };

  const sortedList = requestResult?.result?.operations.sort((a: any, b: any) => moment(b.operation_date).unix() - moment(a.operation_date).unix()) || [];

  return (
    <Box className="tableBox" padding={[2, 0]}>
      <Box padding={[0, 3]} direction="row" justify="space-between">
        <Typography.Title level={5}>{title}</Typography.Title>
        <Box><Button icon={<DownloadOutlined />} onClick={() => downloadList(sortedList)}>Сохранить в файл</Button></Box>
      </Box>
      <Table
        columns={COLUMN}
        dataSource={sortedList.slice((page - 1) * perPage, page * perPage) || []}
        style={{ width: '100%' }}
        size="small"
        scroll={{ x: scroll }}
        loading={isLoading}
        pagination={false}
      />
      {requestResult
      && (
      <Box padding={[0, 2]}>
        <TableFooter perPage={perPage} perPageChange={per => setPerPage(per)} currentPage={page} total={Number(requestResult?.result?.row_count || 0)} paginationChange={pag => setPage(pag)} />
      </Box>
      )}
    </Box>
  );
}

export default TransactionTabs;
