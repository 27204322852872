import { useCallback, useState } from 'react';

import Axios from 'axios';

import { IRequestConfigOzone } from './OzonPage';
// import LocalStorage from 'utils/LocalStorage';

const timeout = 30000;

interface ErrorRequest {
    isError: boolean;
    errorMessage: any;
}

/* const updToken = async () => {

}; */

export default function useRequestOzone({ link, method } : IRequestConfigOzone) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorRequest>({ isError: false, errorMessage: '' });
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [requestResult, setRequestResult] = useState<any>();
  const [metaData, setMetaData] = useState<any>({});

  const { isError, errorMessage } = error;

  const createParams = (obj: any): string => {
    if (obj) {
      let compiledParams = '?';

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          compiledParams += `${key}=${obj[key]}&`;
        }
      }
      compiledParams = compiledParams.slice(0, -1);
      return compiledParams;
    }
    return '';
  };

  const BASE_LINK = 'https://api-seller.ozon.ru/';

  const createRequest = async ({
    token, body, params,
  }: any): Promise<any> => {
    let result: object = {};
    const headers = {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-type': 'application/x-www-form-urlencoded',
      Accept: 'application/json; charset=UTF-8',
      'Client-Id': '773531',
      'Api-Key': 'f3f22abd-6ad6-4218-918d-1aabd9d62c2e',
    };
    const axiosInstance = Axios.create({
      timeout,
      headers,
    });
    const fullLink = `${BASE_LINK}${link}${createParams(params)}`;

    switch (method) {
      case 'get':
        result = axiosInstance.get(fullLink);
        break;
      case 'post':
        result = axiosInstance.post(fullLink, body);
        break;
      case 'put':
        result = axiosInstance.put(fullLink, body);
        break;
      case 'patch':
        result = axiosInstance.patch(fullLink, body);
        break;
      case 'delete':
        result = axiosInstance.delete(fullLink, { data: body });
        break;
      default:
        break;
    }
    return result;
  };

  const interceprot401 = async ({ body, params, addTolink }: any): Promise<any> => {
    const requstData = {
      body,
      params,
      addTolink,
    };
    // here refresh if need

    try {
      const result = await createRequest(requstData);

      setIsSuccess(true);
      setRequestResult(result.data);
      setIsLoading(false);
    } catch (err:any) {
      if (!err.response) return;

      setError({
        isError: true,
        errorMessage: err.response || 'Empty error data',
      });
      setIsLoading(false);
    }
  };

  const dropState = () => {
    setIsLoading(false);
    setIsSuccess(false);
    setError({ isError: false, errorMessage: '' });
    setRequestResult('');
  };

  const fetch = useCallback(
    (body = {}, params = {}, addTolink = '') => {
      setMetaData({
        body,
        params,
        link,
        addTolink,
      });
      setError({ isError: false, errorMessage: '' });
      setIsLoading(true);
      interceprot401({ body, params, addTolink });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [],
  );

  return {
    isLoading, isError, errorMessage, isSuccess, fetch, requestResult, dropState, error, metaData,
  };
}
