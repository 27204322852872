/* eslint-disable max-len */
import {
  computed, decorate, observable, toJS,
} from 'mobx';
import LocalStorage from 'utils/LocalStorage';

export type ILang = 'RU' | 'EN'

export const MOBILE_POINT_MENU = 768;
export const MOBILE_POINT_CONTENT = 414;

export type PaymentServicesType = {
  active: boolean;
  amountLowerBound: number;
  amountUpperBound: number;
  id: number;
  note: string;
  providerName: string;
  providerParams: string;
  providerUrl: string;
}

export type UData = {
  name: string;
  id: number;
  token: string;
}

export type StateData = {
  name: string;
  id: number;
  main_id?: number;
  maintainer?: string;
  type?: number;
  char_code?: string;
}

export type StateDataModificators = {
  name: string;
  id: number;
  main_id: number;
  maintainer: string;
  type: number;
  char_code: string;
}

export type PSType = {
  name: string;
  id: number;
  link: string;
  api_link: string;
  api_ver: number;
  balance_id: number;
}

export type Modificators = {
  list: StateData[];
  maintaners: StateData[];
  types: StateDataModificators[];
}

export type DictionaryType = {
  order_status: StateData[];
  product_status: StateData[];
  product_in_order_state: StateData[];
  stores: StateData[];
  payment_types: StateData[];
  sale_type: StateData[];
  prod_category: StateData[];
  sellers: StateData[];
  modificators: Modificators;
  payment_services: PSType[];
}

type Curr = {
  usd: number;
  cny: number;
  cny_prev: number;
  is_cny_up: boolean;
  usd_prev: number;
  is_usd_up: boolean;
}

class Store {
    screenWidth: number = window.innerWidth;

    updateSubscribe: string[] = [];

    setUpdateSubscribe = (tableName: string) => {
      const l = toJS(this.updateSubscribe);
      l.push(tableName);
      this.updateSubscribe = l;
    }

    removeUpdateSubscribe = (tableName: string) => {
      const l = toJS(this.updateSubscribe);
      this.updateSubscribe = l.filter((el: string) => el !== tableName);
    }

    get updateTableList() {
      return toJS(this.updateSubscribe);
    }

    dictionaries: DictionaryType = {} as DictionaryType;

    setDictionary = (data: DictionaryType) => {
      this.dictionaries = data;
    }

    curr: Curr = {
      usd: 75,
      cny: 11,
      cny_prev: 11,
      is_cny_up: false,
      usd_prev: 75,
      is_usd_up: false,
    }

    setCurr = (data: Curr) => this.curr = data

    userData: UData = LocalStorage.get('aUser') || {} as UData;

    jwtAccess: string = LocalStorage.get('a') || ''

    jwtRefresh: string = LocalStorage.get('r') || ''

    get isAuth() {
      if (Object.keys(this.userData).length) {
        return true;
      }
      return false;
    }

    setUser = ({ id, name, token }: any) => {
      this.userData = {
        name,
        id,
        token,
      };
      LocalStorage.set('aUser', { name, id, token });
    }

    setScreenWidth = (size: number) => {
      this.screenWidth = size;
    }

    updateToken = (token: string, type: 'access' | 'refresh') => {
      if (type === 'access') {
        this.jwtAccess = token;
        LocalStorage.set('a', token);
      } else {
        this.jwtRefresh = token;
        LocalStorage.set('r', token);
      }
    }

    logout = () => {
      LocalStorage.remove('a');
      LocalStorage.remove('r');
      LocalStorage.remove('aUser');
      this.userData = {} as UData;
    }
}

decorate(Store, {
  userData: observable,
  screenWidth: observable,
  isAuth: computed,
  jwtAccess: observable,
  jwtRefresh: observable,
  dictionaries: observable,
  curr: observable,
  updateSubscribe: observable,
  updateTableList: computed,
});

const store = new Store();

export default store;
