import { useState, useEffect } from 'react';

import {
  Badge,
  Button, DatePicker, Input, Menu, Select, Table, Typography,
} from 'antd';
import { Box } from 'components/Box';
import TableFooter from 'components/TableFooter';
import { IRequestConfig } from 'configs';
import useRequest from 'hooks/useRequest';
import './style.scss';
import { useMount } from 'hooks';
import {
  FilterOutlined, DownloadOutlined, ArrowUpOutlined, ArrowDownOutlined,
} from '@ant-design/icons';
import store from 'store/mainStore';

type DownLoadType = {
  func: any;
  limit: number;
  title: string;
}

type Props = {
    title: string;
    extra?: JSX.Element;
    column: any[];
    config: IRequestConfig;
    filters?: any[];
    onRow?: any;
    preapliedFilters?: any;
    scroll?: number;
    menu?: any[];
    menuFilterName?: string;
    menuDefault?: string;
    download?: DownLoadType;
    data?: any[];
    isExpander?: boolean;
    defaultHidden?: boolean;
    getFull?: DownLoadType;
}

function TableBox({
  title,
  extra,
  column,
  config,
  filters = [],
  onRow,
  preapliedFilters = {},
  scroll = 1300,
  menu = [],
  menuFilterName = '',
  menuDefault = '',
  download, data,
  isExpander = false,
  defaultHidden = false,
  getFull,
}: Props) {
  const { updateTableList, removeUpdateSubscribe } = store;
  const { fetch, requestResult, isLoading } = useRequest(config);
  const { fetch: fetchSecondary, isLoading: isLoadingSeondary } = useRequest(config);
  const [hide, setHide] = useState<boolean>(defaultHidden);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filterList, setFilterList] = useState<any>(preapliedFilters);
  const [current, setCurrent] = useState<string>(menuDefault);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  useEffect(() => {
    fetch({}, {
      perPage,
      page,
      ...filterList,
      ...(menuFilterName.length ? { [menuFilterName]: current } : null),
    });
    removeUpdateSubscribe(title);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, fetch]);

  useEffect(() => {
    if (!current) return;
    fetch({}, {
      perPage,
      page,
      ...filterList,
      ...(menuFilterName.length ? { [menuFilterName]: current } : null),
    });
    removeUpdateSubscribe(title);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  useEffect(() => {
    if (page === 1) {
      fetch({}, { perPage, page: 1, ...filterList, ...preapliedFilters, ...(menuFilterName.length ? { [menuFilterName]: current } : null) });
      removeUpdateSubscribe(title);
    }
    setPage(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage]);

  useMount(() => {
    fetch({}, { perPage, page: 1, ...preapliedFilters, ...(menuFilterName.length ? { [menuFilterName]: menuDefault } : null) });
    removeUpdateSubscribe(title);
  });

  const filterHandler = (name: string, value: string) => {
    const list = { ...filterList };
    if (value.length < 1) {
      delete list[name];
    } else {
      list[name] = Array.isArray(value) ? value.join(',') : value;
    }
    setFilterList(list);
  };

  const getFilter = () => {
    setPage(1);
    fetch({}, { perPage, page: 1, ...filterList, ...preapliedFilters, ...(menuFilterName.length ? { [menuFilterName]: current } : null) });
    removeUpdateSubscribe(title);
  };

  const dropFilter = () => {
    setFilterList({});
    setPage(1);
    setShowFilters(false);
    fetch({}, { perPage, page: 1, ...preapliedFilters, ...(menuFilterName.length ? { [menuFilterName]: current } : null) });
  };

  const createFilters = () => {
    const list = [];
    for (let i = 0; i < filters.length; i++) {
      switch (filters[i].type) {
        case 'select':
          list.push(
            <Select
              mode="multiple"
              allowClear
              style={{ width: '200px' }}
              placeholder={filters[i].placeholder}
              // defaultValue={['a10', 'c12']}
              onChange={(v: any) => filterHandler(filters[i].name, v)}
              options={filters[i].options}
              loading={filters[i].isLoading}
              showSearch
              filterOption={(input: any, option: any) => (option?.label || '').toLowerCase().includes(input.toLowerCase())}
            />,
          );
          break;
        case 'input':
          list.push(
            <Input
              style={{ width: '200px', height: '32px' }}
              placeholder={filters[i].placeholder}
              onChange={(v: any) => filterHandler(filters[i].name, v.target.value)}
            />,
          );
          break;
        case 'date':
          list.push(
            <DatePicker
              style={{ width: '200px', height: '32px' }}
              placeholder={filters[i].placeholder}
              onChange={(v: any) => filterHandler(filters[i].name, v.format('YYYY-MM-DD'))}
            />,
          );
          break;
        default:
          continue;
      }
    }
    return list;
  };

  useEffect(() => {
    if (updateTableList.includes(title)) {
      fetch({}, { perPage, page, ...filterList, ...preapliedFilters, ...(menuFilterName.length ? { [menuFilterName]: current } : null) });
      removeUpdateSubscribe(title);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTableList]);

  const getList = () => {
    fetchSecondary({}, { perPage: download?.limit, page: 1, ...filterList, ...preapliedFilters, ...(menuFilterName.length ? { [menuFilterName]: current } : null) }, '', download?.func);
  };

  const getFullList = () => {
    fetchSecondary({}, { perPage: getFull?.limit, page: 1, ...filterList, ...preapliedFilters, ...(menuFilterName.length ? { [menuFilterName]: current } : null) }, '', getFull?.func);
  };

  const BoxMenu = ({ count, name, id }: any) => (
    <Badge.Ribbon text={count}>
      <Box
        className={`menuTableBox_${current === id ? 'active' : 'stock'}`}
        padding={[0, 5]}
      >
        {name}
      </Box>
    </Badge.Ribbon>
  );

  const getColor = (state: string) => {
    switch (Number(state)) {
      case 1:
        return 'green';
      case 9:
        return 'cyan';
      case 8:
        return 'red';
      default:
        return 'blue';
    }
  };

  const m = menu?.map((el: any) => ({
    label: <BoxMenu count={el.count} name={el.label} id={el.key} color={getColor(el.key)} />,
    key: el.key,
  }));

  return (
    <Box className="tableBox" padding={[2, 0]}>
      <Box direction="row" justify="space-between" align="center" padding={[0, 2]} margin={[0, 0, 2, 0]}>
        <Box direction="row" align="center">
          <Typography.Title level={5}>{title}</Typography.Title>
          {
            filters?.length
              ? (
                <Box direction="row" align="center" style={{ cursor: 'pointer' }} onClick={() => setShowFilters(!showFilters)} margin={[0, 0, 0.6, 2]}>
                  <Typography.Text type="secondary">
                    Фильтры
                  </Typography.Text>
                  <FilterOutlined style={{ color: '#BDBDBD', marginLeft: 10 }} />
                </Box>
              ) : null
        }
        </Box>
        <Box direction="row" style={{ gap: 20 }}>
          {extra ? <Box>{extra}</Box> : null}
          {download ? <Box><Button loading={isLoadingSeondary} icon={<DownloadOutlined />} onClick={getList}>{download.title}</Button></Box> : null}
          {getFull ? <Box><Button loading={isLoadingSeondary} icon={<DownloadOutlined />} onClick={getFullList}>{getFull.title}</Button></Box> : null}
          {isExpander ? <Box><Button icon={hide ? <ArrowDownOutlined /> : <ArrowUpOutlined />} onClick={() => setHide(!hide)}>{hide ? 'Развернуть' : 'Свернуть'}</Button></Box> : null}
        </Box>
      </Box>
      <Box className={hide ? 'tableBox_close' : 'tableBox_open'}>
        {
        filters?.length && showFilters
          ? (
            <Box direction="row" justify="space-between" margin={[0, 2, 2, 2]}>
              <Box direction="row" width="100%" style={{ gridGap: '20px' }}>
                {createFilters()}
              </Box>
              <Box direction="row" style={{ gridGap: '10px' }}>
                <Button onClick={dropFilter}>Сбросить</Button>
                <Button type="primary" onClick={getFilter}>Применить</Button>
              </Box>
            </Box>
          ) : null
      }
        <Menu
          mode="horizontal"
          onClick={(e: any) => setCurrent(e.key)}
          selectedKeys={[current]}
          items={m}
        />
        <Table
          columns={column}
          dataSource={data || requestResult?.records || []}
          style={{ maxWidth: '1300px' }}
          size="small"
          scroll={{ x: scroll }}
          onRow={onRow}
          loading={isLoading}
          pagination={false}
        />
        {requestResult && !data
      && (
      <Box padding={[0, 2]}>
        <TableFooter perPage={perPage} perPageChange={per => setPerPage(per)} currentPage={page} total={Number(requestResult?.paging || 0)} paginationChange={pag => setPage(pag)} />
      </Box>
      )}
      </Box>
    </Box>
  );
}

export default TableBox;
