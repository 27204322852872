import { useEffect, useState } from 'react';

import useRequest from 'hooks/useRequest';
import { getProductList, getSellsFlow, getStores } from 'configs';
import { useMount } from 'hooks';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import {
  PageHeader, Divider, Tag, Typography, Col, Row, Progress, Button,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Box } from 'components/Box';
import { EditOutlined, CaretDownOutlined } from '@ant-design/icons';
import BorderedBox from 'components/BorderedBox/BorderedBox';
import TableBox from 'components/TableBox/TableBox';
import store from 'store/mainStore';
import sellStorage from 'store/sellStore';
import StoreModal from 'pages/Orders/StoreModal';
import DataList, { DataListUnit } from 'components/BorderedBox/DataList';
import { saveToFile } from 'utils';
import Papa from 'papaparse';

import ProductModal from './ProductModal';

const COLUMN_SELL_FLOW = [
  {
    title: 'Продавец',
    dataIndex: 'seller_name',
    minWidth: 250,
    ellipsis: true,
  },
  {
    title: 'Дата продажи',
    dataIndex: 'date_created',
    minWidth: 250,
    ellipsis: true,
  },
  {
    title: 'Кол-во',
    dataIndex: 'count_sell',
    minWidth: 250,
    ellipsis: true,
  },
  {
    title: 'Цена',
    dataIndex: 'unit_price',
    minWidth: 250,
    ellipsis: true,
  },
  {
    title: 'Цена закупка',
    dataIndex: 'fabric_coast',
    minWidth: 250,
    ellipsis: true,
  },
  {
    title: 'Оплачено',
    dataIndex: 'pay_sum',
    minWidth: 250,
    ellipsis: true,
  },
  {
    title: 'CC',
    dataIndex: 'pre_price',
    minWidth: 70,
    ellipsis: true,
  },
  {
    title: 'Налог',
    dataIndex: 'tax',
    minWidth: 70,
    ellipsis: true,
  },
  {
    title: 'Комиссия',
    dataIndex: 'payment_tax',
    minWidth: 70,
    ellipsis: true,
  },
  {
    title: 'Прибыль',
    dataIndex: 'profit',
    minWidth: 70,
    ellipsis: true,
  },
  {
    title: 'Не оплачено',
    dataIndex: 'credit',
    minWidth: 250,
    ellipsis: true,
    render: (row: number) => <Typography.Text strong type={row > 0 ? 'danger' : 'success'}>{row}</Typography.Text>,
  },
];

const getAverage = (numbers: number[]) => {
  if (!numbers.length) return 0;
  const sum = numbers.reduce((acc, number) => acc + number, 0);
  const { length } = numbers;
  return sum / length;
};

const ProductStats = () => {
  const { id } = useParams<any>();
  const { fetch, requestResult } = useRequest(getProductList);
  const { fetch: fetchFlow, requestResult: flow } = useRequest(getSellsFlow);
  const { dictionaries, updateTableList, removeUpdateSubscribe, setUpdateSubscribe } = store;
  const { addToSell } = sellStorage;
  const [storeOpen, setStoreOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [product, setProduct] = useState<any>({});

  const [storeData, setStoreData] = useState<any>({});
  const [OLID, setOLID] = useState<number>(0);

  const typeOptions = (dictionaries?.sale_type || []).map((el: any) => ({
    label: el.name,
    value: el.id,
    search: el.name,
  })) || [];

  const storeOptions = (dictionaries?.stores || [])
    .filter((el: any) => ['work'].includes(el.type))
    .map((el: any) => ({ label: el.name, value: el.id, search: el.name })) || [];

  const filtersStore = [
    {
      name: 'store',
      type: 'select',
      label: 'Склад',
      placeholder: 'Выберите склад',
      options: storeOptions,

    },
  ];

  const StoreToogle = (order_list_id: number, sd: any = {}) => {
    setOLID(order_list_id);
    setStoreData(sd);
    setStoreOpen(!storeOpen);
  };

  const COLUMN = [
    {
      title: 'Склад',
      dataIndex: 'store_name',
      ellipsis: true,
      width: 140,
      render: (row: any) => (
        <Tag>
          {row}
        </Tag>
      ),
    },
    {
      title: 'Заказ',
      dataIndex: 'order_number',
      ellipsis: true,
      width: 100,
    },
    {
      title: 'Было/Прод/Рез/Ост',
      dataIndex: 'count',
      ellipsis: true,
      render: (row: any, rows: any) => (
        <>
          <Typography.Text strong>{row || 0}</Typography.Text>
          <Divider type="vertical" />
          <Typography.Text strong type={rows.count_sell < 1 ? 'danger' : 'success'}>{rows.count_sell || 0}</Typography.Text>
          <Divider type="vertical" />
          <Typography.Text strong type="secondary">{rows.reserve}</Typography.Text>
          <Divider type="vertical" />
          <Typography.Text strong type={rows.stock < 1 ? 'danger' : 'success'}>{rows.stock}</Typography.Text>
        </>
      ),
    },
    {
      title: 'Переместить',
      dataIndex: 'last_update',
      ellipsis: true,
      render: (row: any, rows: any) => (<Button onClick={() => StoreToogle(rows.order_list_id, { from_id: rows.store_id, max: rows.stock })}>Переместить</Button>),
    },
    {
      title: 'Продать',
      dataIndex: 'last_update',
      minWidth: 70,
      ellipsis: true,
      render: (_: any, r: any) => (<Button type="primary" onClick={() => addToSell(r)}>Продать</Button>),
    },
  ];

  const filters = [
    {
      name: 'state',
      type: 'select',
      label: 'Статус',
      placeholder: 'Выберите статус',
      options: typeOptions,
    },
    {
      name: 'pay_sum',
      type: 'input',
      label: 'Сумма',
      placeholder: 'Введите сумму',
    },
    {
      name: 'date',
      type: 'date',
      label: 'Дата',
      placeholder: 'Выберите дату закрытия',
    },
  ];

  // const { fetch: fetchStores, requestResult: stores } = useRequest(getStores);

  const positive_list = (flow?.records || [])
    .filter((el: any) => Number(el.unit_price) > 0);

  const up_price = positive_list.map((el: any) => el.up_price);

  const total_amount = positive_list.reduce(
    (accumulator: any, currentValue: any) => accumulator + Number(currentValue.pay_sum), 0,
  );

  const total_amount_our = positive_list.reduce(
    (accumulator: any, currentValue: any) => accumulator + Number(currentValue.profit), 0,
  );

  const total_amount_fab_cred = positive_list.reduce(
    (accumulator: any, currentValue: any) => accumulator + Number(currentValue.fabric_coast), 0,
  );

  // MARGIN

  const marginlist = positive_list.map((el: any) => el.marginal);

  const mPrices = positive_list.map((el: any) => el.pay_sum / el.count_sell);

  const mProfit = positive_list.map((el: any) => el.profit / el.count_sell);

  const marginalMiddle = marginlist.reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue, 0,
  );

  const marg = Number(((marginalMiddle / marginlist.length) * 100).toFixed(2));

  const total_null = (flow?.records || [])
    .filter((el: any) => Number(el.unit_price) === 0);

  const total_null_amount = total_null.reduce(
    (accumulator: any, currentValue: any) => accumulator + Number(currentValue.profit), 0,
  );

  useMount(() => {
    fetch({}, { id });
    fetchFlow({}, { product_id: id, perPage: 100000 });
  });

  const toogleModal = (state: boolean, prod: any = {}) => {
    setProduct(prod);
    setIsOpen(state);
    if (!state) {
      setUpdateSubscribe('Товар');
      setProduct(null);
    }
  };

  useEffect(() => {
    if (updateTableList.includes('Товар')) {
      fetch({}, { id });
      fetchFlow({}, { product_id: id, perPage: 100000 });
      removeUpdateSubscribe('Товар');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTableList]);

  const Vector = ({ price, low }: any) => {
    const percentageLow = (Number(low) - price);
    const v = percentageLow > 0 ? percentageLow / (low / 100) : 0;
    if (v === 0) return <></>;
    return (
      <Box direction="row" align="center">
        <Typography.Text type="danger">{`${v.toFixed(2)}%`}</Typography.Text>
        <CaretDownOutlined style={{ color: 'red' }} />
      </Box>
    );
  };

  const tList: DataListUnit[] = [
    {
      title: 'Cсылка ВК',
      value: requestResult?.records[0]?.link_vk.length ? <a href={requestResult?.records[0]?.link_vk || '#'} target="_blank" rel="noreferrer">Перейти</a> : '---',
    },
    {
      title: 'Цена ВК',
      value: requestResult?.records[0]?.price_vk,
    },
    {
      title: 'Cсылка Авито',
      value: requestResult?.records[0]?.link_avito.length ? <a href={requestResult?.records[0]?.link_avito || '#'} target="_blank" rel="noreferrer">Перейти</a> : '---',
    },
    {
      title: 'Цена Авито',
      value: requestResult?.records[0]?.price_avito,
    },
    {
      title: 'Верх рынка',
      value: requestResult?.records[0]?.market_price_max,
    },
    {
      title: 'Низ рынка',
      value: requestResult?.records[0]?.market_price_min,
    },
    {
      title: 'Розница',
      value: requestResult?.records[0]?.rec_price,
    },
    {
      title: 'Опт/спец',
      value: requestResult?.records[0]?.rec_price_sale,
    },
  ];

  const downloadList = (list: any) => {
    const prepareObject = list.records.map((el: any) => ({
      Товар: requestResult?.records[0].name,
      Продавец: el.seller_name,
      Дата: el.date_created,
      'Кол-во': el.count_sell,
      Цена: el.unit_price,
      Оплачено: el.pay_sum,
      CC: el.pre_price,
      Налог: el.tax,
      Комиссия: el.payment_tax,
      Прибыль: el.profit,
      'Не оплачено': el.credit,
    }));
    const fields = ['Товар', 'Продавец', 'Дата', 'Кол-во', 'Цена', 'Оплачено', 'CC', 'Налог', 'Комиссия', 'Прибыль', 'Не оплачено'];
    const config: any = { delimiter: ';' };
    const csv2Export = Papa.unparse({ data: prepareObject, fields }, config);
    saveToFile(csv2Export, `Продажи_${requestResult?.records[0].name}.csv`);
  };

  return (
    <>
      <StoreModal isOpen={storeOpen} toogle={StoreToogle} order_list_id={OLID} getOrder={() => {}} notDelivery initValues={storeData} />

      <PageHeader
        title={(
          <Box direction="row" align="center">
            {`Статистика по товару: ${requestResult?.records[0].name || ''}`}
            <EditOutlined style={{ color: 'grey', marginLeft: '20px', cursor: 'pointer' }} onClick={() => toogleModal(true, requestResult?.records[0])} />
          </Box>
)}
      />
      <ProductModal isOpen={isOpen} toogle={toogleModal} product={product} />

      <Content style={{ width: '90vw', maxWidth: 1200 }}>
        <Row>
          <Col span={18} push={7}>
            <Box>
              <TableBox
                title="Список продаж"
                preapliedFilters={{ product_id: id }}
                column={COLUMN_SELL_FLOW}
                filters={filters}
                isExpander
                scroll={500}
                download={{
                  func: downloadList,
                  limit: 10000,
                  title: 'Сохранить в файл',
                }}
                config={getSellsFlow}
              />
              <Divider />
              <TableBox
                title="На складах"
                column={COLUMN}
                isExpander
                filters={filtersStore}
                scroll={500}
                preapliedFilters={
                {
                  stock: 1,
                  id,
                }
              }
                config={getStores}
              />
            </Box>
          </Col>
          <Col span={6} pull={18}>
            <BorderedBox>
              <Box align="center" padding={[1, 0, 1, 0]}>
                <Typography.Title level={5}>Маржинальность</Typography.Title>
                <Divider style={{ marginTop: 5 }} />
                <Progress type="dashboard" percent={marg} strokeColor={marg < 30 ? '#F4664A' : marg < 80 ? '#FAAD14' : '#30BF78'} />
                <Divider />
                <Box direction="row" justify="space-between" width="100%">
                  <Typography.Link>Получено с продаж</Typography.Link>
                  <Typography.Text strong>{total_amount.toLocaleString()}</Typography.Text>
                </Box>
                <Box direction="row" justify="space-between" width="100%">
                  <Typography.Link>Заработано</Typography.Link>
                  <Typography.Text strong>{total_amount_our.toLocaleString()}</Typography.Text>
                </Box>
                <Box direction="row" justify="space-between" width="100%">
                  <Typography.Link>Оплатить фабрике</Typography.Link>
                  <Typography.Text strong>{total_amount_fab_cred.toLocaleString()}</Typography.Text>
                </Box>
                <Box direction="row" justify="space-between" width="100%">
                  <Typography.Link>Средняя цена</Typography.Link>
                  <Box direction="row">
                    <Vector price={getAverage(mPrices).toFixed(2)} up={requestResult?.records[0]?.market_price_max} low={requestResult?.records[0]?.market_price_min} />
                    <Typography.Text strong>{getAverage(mPrices).toFixed(2)}</Typography.Text>
                  </Box>
                </Box>
                <Box direction="row" justify="space-between" width="100%">
                  <Typography.Link>Средняя наценка</Typography.Link>
                  <Typography.Text strong>
                    {getAverage(up_price).toFixed(2)}
                    %
                  </Typography.Text>
                </Box>
                <Box direction="row" justify="space-between" width="100%">
                  <Typography.Link>Цены</Typography.Link>
                  <Typography.Text strong>{`${mPrices.length ? Math.min(...mPrices).toFixed(2) : 0} - ${mPrices.length ? Math.max(...mPrices).toFixed(2) : 0}`}</Typography.Text>
                </Box>
                <Box direction="row" justify="space-between" width="100%">
                  <Typography.Link>Прибыль</Typography.Link>
                  <Typography.Text strong>{`${mProfit.length ? Math.min(...mProfit).toFixed(2) : 0} - ${mProfit.length ? Math.max(...mProfit).toFixed(2) : 0}`}</Typography.Text>
                </Box>
                <Box direction="row" justify="space-between" width="100%">
                  <Typography.Link>Подарено</Typography.Link>
                  <Typography.Text strong>{total_null_amount.toLocaleString()}</Typography.Text>
                </Box>
              </Box>
            </BorderedBox>

            <BorderedBox top={3}>
              <DataList list={tList} />
              {/* <Box>
                {linkList.map((el: any) => (
                  <Box>
                    <Typography.Text>
                      {el.name}
                    </Typography.Text>
                    <Box>
                      {'>'}
                    </Box>
                  </Box>
                ))}
              </Box> */}
            </BorderedBox>
          </Col>
        </Row>

      </Content>
    </>
  );
};

export default observer(ProductStats);
