import {

  PageHeader, Tag, Typography,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { getStoreFlow } from 'configs';
import { observer } from 'mobx-react';
import TableBox from 'components/TableBox/TableBox';
import store from 'store/mainStore';

const StoreFlow = () => {
  const { dictionaries } = store;

  const typeOptions = (dictionaries?.stores || []).map((el: any) => ({
    label: el.name,
    value: el.id,
    search: el.name,
  })) || [];
  const COLUMN = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 60,
      render: (id: any) => <Typography.Text>{id}</Typography.Text>,
    },
    {
      title: 'Отправитель',
      dataIndex: 'from_name',
      minWidth: 70,
      ellipsis: true,
      render: (row: any) => (
        <Tag>
          {row}
        </Tag>
      ),
    },
    {
      title: 'Получатель',
      dataIndex: 'to_name',
      minWidth: 70,
      ellipsis: true,
      render: (row: any) => (
        <Tag>
          {row}
        </Tag>
      ),
    },
    {
      title: 'Дата',
      dataIndex: 'date',
      minWidth: 70,
      ellipsis: true,
    },
    {
      title: 'Заказ',
      dataIndex: 'order_number',
      minWidth: 70,
      ellipsis: true,
    },
    {
      title: 'Товар',
      dataIndex: 'product_name',
      minWidth: 70,
      ellipsis: true,
    },
    {
      title: 'Количество',
      dataIndex: 'count',
      minWidth: 70,
      ellipsis: true,
    },
  ];

  const filters = [
    {
      name: 'from_id',
      type: 'select',
      label: 'От',
      placeholder: 'Выберите от кого',
      options: typeOptions,

    },
    {
      name: 'to_id',
      type: 'select',
      label: 'Кому',
      placeholder: 'Выберите для кого',
      options: typeOptions,
    },
    {
      name: 'name',
      type: 'input',
      label: 'Наименование товара',
      placeholder: 'Введите наименование',
    },
    {
      name: 'date',
      type: 'date',
      label: 'Дата',
      placeholder: 'Выберите дату закрытия',
    },
  ];

  return (
    <>
      <PageHeader title="Складские перемещения" />
      <Content>
        <TableBox
          title="Список товаров"
          filters={filters}
          column={COLUMN}
          config={getStoreFlow}
        />

      </Content>
    </>
  );
};
export default observer(StoreFlow);
