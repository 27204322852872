export const BASE_LINK_DEV = 'http://back.gosslofj.beget.tech/';
export const BASE_LINK_PROD = 'https://dkcrm.ru/';

export const BASE_LINK = BASE_LINK_PROD;

export const API_VERSION = 'api/';

export enum RequestTypes {
    get = 'get',
    post = 'post',
    put = 'put',
    delete = 'delete',
    patch = 'patch',
  }

export enum ApiLinks {
    getOrderState= 'order/order_state.php',
    deleteFromOrder= 'order/delete_from_order.php',
    getProductList = 'product/product_list.php',
    addProduct = 'product/create.php',
    productStats = 'product/product_stats.php',
    addSeller = 'sellers/create.php',
    editProduct = 'product/update.php',
    getOrdersList = 'order/list.php',
    getOrdersOne = 'order/read_one.php',
    getOrdersProductList = 'order/product_list_order.php',
    addOrder = 'order/create.php',
    crateOrder = 'order/add_product.php',
    editOrder = 'order/edit_order.php',
    usersList = 'users/user_list.php',
    usersLogin = 'users/login.php',
    dict= 'dict/read.php',
    dictCurr= 'dict/read_curr.php',
    stiller= 'alistiller/still.php',
    getPersonalBalances= 'balances/personal_list.php',
    getBalanceFlow= 'balances/balance_flow.php',
    getPrepaid= 'balances/prepaids.php',
    makeBalanceTransaction= 'balances/create_payment.php',
    makeBalanceTransactionInvest= 'balances/create_payment_invest.php',
    storeTransferDelivery= 'store/store_transfer_delivery.php',
    storeTransfer= 'store/store_transfer.php',
    storeFlow= 'store/store_flow.php',
    stores= 'store/stores.php',
    getMonitorAmount= 'store/stores_cash_hold.php',
    sell= 'sell/create_sell.php',
    updatesell= 'sell/update_sell.php',
    sellList= 'sell/list.php',
    sellTotal= 'sell/total.php',
    sellFlow= 'sell/list_card.php',
    pushSell= 'sell/push_sell.php',
    pushPreSell = 'sell/push_pre_sell.php',
    yooInvoices = 'yookassa/get_invoices.php',
    yooRecipient = 'yookassa/get_recipient.php',
    listOzonProd = 'ozon/product_list.php',
}

export interface IRequestConfig {
    link: '' | ApiLinks;
    method: '' | RequestTypes;
    tag?: string;
    version?: string;
}
// OZON
export const listOzonProd: IRequestConfig = {
  link: ApiLinks.listOzonProd,
  method: RequestTypes.get,
  version: 'v2/',
};
// OZON

// PRODUCTS

export const getProductList: IRequestConfig = {
  link: ApiLinks.getProductList,
  method: RequestTypes.get,
  version: 'v2/',
};

export const getInvoices: IRequestConfig = {
  link: ApiLinks.yooInvoices,
  method: RequestTypes.get,
  version: 'v2/',
};

export const getRecipient: IRequestConfig = {
  link: ApiLinks.yooRecipient,
  method: RequestTypes.get,
  version: 'v2/',
};

export const editProduct: IRequestConfig = {
  link: ApiLinks.editProduct,
  method: RequestTypes.post,
  version: 'v2/',
};

export const addProduct: IRequestConfig = {
  link: ApiLinks.addProduct,
  method: RequestTypes.post,
  version: 'v2/',
};

export const getProdStats: IRequestConfig = {
  link: ApiLinks.productStats,
  method: RequestTypes.get,
  version: 'v2/',
};

// PRODUCTS

// DICT

export const getCurr: IRequestConfig = {
  link: ApiLinks.dictCurr,
  method: RequestTypes.get,
};

export const getDict: IRequestConfig = {
  link: ApiLinks.dict,
  method: RequestTypes.get,
};

// DICT

export const getSells: IRequestConfig = {
  link: ApiLinks.sellList,
  method: RequestTypes.get,
  version: 'v2/',
};

export const getSellsFlow: IRequestConfig = {
  link: ApiLinks.sellFlow,
  method: RequestTypes.get,
  version: 'v2/',
};

export const getSellsTotal: IRequestConfig = {
  link: ApiLinks.sellTotal,
  method: RequestTypes.get,
  version: 'v2/',
};

export const getPrepaid: IRequestConfig = {
  link: ApiLinks.getPrepaid,
  method: RequestTypes.get,
};

export const getMonitorAmount: IRequestConfig = {
  link: ApiLinks.getMonitorAmount,
  method: RequestTypes.get,
};

export const pushSell: IRequestConfig = {
  link: ApiLinks.pushSell,
  method: RequestTypes.post,
  version: 'v2/',
};

export const pushPreSell: IRequestConfig = {
  link: ApiLinks.pushPreSell,
  method: RequestTypes.post,
  version: 'v2/',
};

export const createSeller: IRequestConfig = {
  link: ApiLinks.addSeller,
  method: RequestTypes.post,
};

export const createSellEp: IRequestConfig = {
  link: ApiLinks.sell,
  method: RequestTypes.post,
  version: 'v2/',
};

export const updateSell: IRequestConfig = {
  link: ApiLinks.updatesell,
  method: RequestTypes.post,
  version: 'v2/',
};

export const getPersonalBalances: IRequestConfig = {
  link: ApiLinks.getPersonalBalances,
  method: RequestTypes.get,
};

export const getOrdersOne: IRequestConfig = {
  link: ApiLinks.getOrdersOne,
  method: RequestTypes.get,
};

export const getOrderState: IRequestConfig = {
  link: ApiLinks.getOrderState,
  method: RequestTypes.get,
};

export const stiller: IRequestConfig = {
  link: ApiLinks.stiller,
  method: RequestTypes.get,
};

// STORE
export const storeTransferDelivery: IRequestConfig = {
  link: ApiLinks.storeTransferDelivery,
  method: RequestTypes.post,
};

export const storeTransfer: IRequestConfig = {
  link: ApiLinks.storeTransfer,
  method: RequestTypes.post,
};

export const getStoreFlow : IRequestConfig = {
  link: ApiLinks.storeFlow,
  method: RequestTypes.get,
  version: 'v2/',
};

export const getStores : IRequestConfig = {
  link: ApiLinks.stores,
  method: RequestTypes.get,
  version: 'v2/',
};

// STORE

export const makeBalanceTransaction: IRequestConfig = {
  link: ApiLinks.makeBalanceTransaction,
  method: RequestTypes.post,
};

export const getBalanceFlow: IRequestConfig = {
  link: ApiLinks.getBalanceFlow,
  method: RequestTypes.get,
  version: 'v2/',
};

export const makeBalanceTransactionInvest: IRequestConfig = {
  link: ApiLinks.makeBalanceTransactionInvest,
  method: RequestTypes.post,
};

export const usersLogin: IRequestConfig = {
  link: ApiLinks.usersLogin,
  method: RequestTypes.post,
};

export const getOrdersList: IRequestConfig = {
  link: ApiLinks.getOrdersList,
  method: RequestTypes.get,
  version: 'v2/',
};

export const crateOrder: IRequestConfig = {
  link: ApiLinks.crateOrder,
  method: RequestTypes.post,
  version: 'v2/',
};

export const editOrder: IRequestConfig = {
  link: ApiLinks.editOrder,
  method: RequestTypes.post,
  version: 'v2/',
};

export const getOrdersProductList: IRequestConfig = {
  link: ApiLinks.getOrdersProductList,
  method: RequestTypes.get,
  version: 'v2/',
};

export const deleteFromProductList: IRequestConfig = {
  link: ApiLinks.deleteFromOrder,
  method: RequestTypes.post,
};
